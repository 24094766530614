import { render, staticRenderFns } from "./CPrgTreeTable.vue?vue&type=template&id=2871e9c1&scoped=true&"
import script from "./CPrgTreeTable.vue?vue&type=script&lang=ts&"
export * from "./CPrgTreeTable.vue?vue&type=script&lang=ts&"
import style0 from "./CPrgTreeTable.vue?vue&type=style&index=0&id=2871e9c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2871e9c1",
  null
  
)

export default component.exports