












































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

interface ISelected {
    katIndx: number;
    clssIndx: number;
    pclIndx: number;
    spfIndx: number;
    [propName: string]: any;
}

interface IMonthVal {
    month0: number | string |null;
    month1: number | string |null;
    month2: number | string |null;
    month3: number | string |null;
    month4: number | string |null;
    month5: number | string |null;
    month6: number | string |null;
    month7: number | string |null;
    month8: number | string |null;
    month9: number | string |null;
    month10: number | string |null;
    month11: number | string |null;
    [propName: string]: any;
}

interface IMonthTotal {
    month0: number;
    month1: number;
    month2: number;
    month3: number;
    month4: number;
    month5: number;
    month6: number;
    month7: number;
    month8: number;
    month9: number;
    month10: number;
    month11: number;
}

const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

@Component({
    name: 'c-inc-tree-table'
})
export default class CIncomeTreeTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: any;

    @Prop({
        required: true,
        default: []
    })
    private editedMonths!: number[]; // 0-11

    private treeData: any[] = [];

    private collapseAll = false;

    private selectedRow: ISelected | null = null;

    private get editedArr() {
        const editedArr = [false, false, false, false, false, false, false, false, false, false, false, false];
        if (!this.accessLevel.editAccess) { return editedArr; }
        for (const el of this.editedMonths) {
            editedArr[el] = true;
        }
        return editedArr;
    }

    private getTreeData() {
        if (!this.tableData) {
            this.treeData = [];
            return;
        }
        if (this.selectedRow) {
            if (this.selectedRow.katIndx >= this.treeData.length) {
                this.selectedRow = null;
            } else {
                if (this.selectedRow.clssIndx >= this.treeData[this.selectedRow.katIndx].child_list.length) {
                    this.selectedRow = null;
                } else {
                    if (this.selectedRow.pclIndx >= this.treeData[this.selectedRow.katIndx].child_list[this.selectedRow.clssIndx].child_list.length) {
                        this.selectedRow = null;
                    } else {
                        if (this.selectedRow.spfIndx >= this.treeData[this.selectedRow.katIndx].child_list[this.selectedRow.clssIndx].child_list[this.selectedRow.pclIndx].child_list.length) {
                            this.selectedRow = null;
                        }
                    }
                }
            }
        }

        const treeData: any[]= [];
        const totalSum: IMonthTotal = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 };
        const tableData = JSON.parse(JSON.stringify(this.tableData.slice()));
        for (let i = 0; i < tableData.length; i++) {
            const el = tableData[i];
            const objKat = Object.assign({}, el);
            const totalKat = Object.assign({}, totalSum);
            if (objKat.child_list && objKat.child_list.length) {
                if (objKat.collapse === undefined) { objKat.collapse = this.collapseAll; }
                for (let j = 0; j< objKat.child_list.length; j++) {
                    objKat.child_list.sort((a: any, b: any) => (a.cls > b.cls) ? 1 : -1);
                    const elClss = objKat.child_list[j];
                    const totalClss = Object.assign({}, totalSum);
                    if (elClss.child_list && elClss.child_list.length) {
                        if (elClss.collapse === undefined) { elClss.collapse = this.collapseAll; }
                        elClss.child_list.sort((a: any, b: any) => (a.pcl > b.pcl) ? 1 : -1);
                        for (let p = 0; p < elClss.child_list.length; p++) {
                            const elPcl = elClss.child_list[p];

                            if (elPcl.collapse === undefined) { elPcl.collapse = this.collapseAll; }
                            const totalPcl = Object.assign({}, totalSum);

                            if (elPcl.child_list && elPcl.child_list.length) {
                                for (let s = 0; s < elPcl.child_list.length; s++) {
                                    elPcl.child_list.sort((a: any, b: any) => (a.spf > b.spf) ? 1 : -1);
                                    const elSpf = elPcl.child_list[s];
                                    elSpf._katIndx = i;
                                    elSpf._clssIndx = j;
                                    elSpf._pclIndx = p;
                                    elSpf._spfIndx = s;
                                    elSpf.selectedRow = null;
                                    this.getSumTotal(totalPcl, elSpf);
                                }
                                elPcl.totalSum = totalPcl;
                                this.getSumTotal(totalClss, totalPcl);
                            }
                        }
                        elClss.totalSum = totalClss;
                        this.getSumTotal(totalKat, totalClss);
                    }
                }
            }
            objKat.totalSum = totalKat;
            treeData.push(objKat);
            treeData.sort((a: any, b: any) => (a.kat > b.kat) ? 1 : -1);
        }
        this.treeData = treeData;
        if (this.selectedRow) { this.selectRow(this.selectedRow.katIndx, this.selectedRow.clssIndx, this.selectedRow.pclIndx, this.selectedRow.spfIndx); }
    }

    private getSumTotal(total: any, obj: IMonthVal) {
        if (obj.month0) { total.month0 = Math.round(((typeof obj.month0 == 'number' ? obj.month0 : parseFloat(obj.month0)) + total.month0)*10)/10; }
        if (obj.month1) { total.month1 = Math.round(((typeof obj.month1 == 'number' ? obj.month1 : parseFloat(obj.month1)) + total.month1)*10)/10; }
        if (obj.month2) { total.month2 = Math.round(((typeof obj.month2 == 'number' ? obj.month2 : parseFloat(obj.month2)) + total.month2)*10)/10; }
        if (obj.month3) { total.month3 = Math.round(((typeof obj.month3 == 'number' ? obj.month3 : parseFloat(obj.month3)) + total.month3)*10)/10; }
        if (obj.month4) { total.month4 = Math.round(((typeof obj.month4 == 'number' ? obj.month4 : parseFloat(obj.month4)) + total.month4)*10)/10; }
        if (obj.month5) { total.month5 = Math.round(((typeof obj.month5 == 'number' ? obj.month5 : parseFloat(obj.month5)) + total.month5)*10)/10; }
        if (obj.month6) { total.month6 = Math.round(((typeof obj.month6 == 'number' ? obj.month6 : parseFloat(obj.month6)) + total.month6)*10)/10; }
        if (obj.month7) { total.month7 = Math.round(((typeof obj.month7 == 'number' ? obj.month7 : parseFloat(obj.month7)) + total.month7)*10)/10; }
        if (obj.month8) { total.month8 = Math.round(((typeof obj.month8 == 'number' ? obj.month8 : parseFloat(obj.month8)) + total.month8)*10)/10; }
        if (obj.month9) { total.month9 = Math.round(((typeof obj.month9 == 'number' ? obj.month9 : parseFloat(obj.month9)) + total.month9)*10)/10; }
        if (obj.month10) { total.month10 = Math.round(((typeof obj.month10 == 'number' ? obj.month10 : parseFloat(obj.month10)) + total.month10)*10)/10; }
        if (obj.month11) { total.month11 = Math.round(((typeof obj.month11 == 'number' ? obj.month11 : parseFloat(obj.month11)) + total.month11)*10)/10; }
    }

    private clkCollapse(katIndx: number | null, clssIndx: number | null, pclIndx: number | null, collapse?: boolean) {
        if (katIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.treeData) {
                el.collapse = this.collapseAll;
                for (const clssEl of el.child_list) {
                    clssEl.collapse = this.collapseAll;
                    for (const pclEl of clssEl.child_list) {
                        if (pclEl.collapse!==undefined) { pclEl.collapse = this.collapseAll; }
                    }
                }
            }
            return;
        }

        if (collapse === undefined) { return; }

        if (clssIndx === null) {
            this.treeData[katIndx].collapse = collapse;
            if (!collapse && this.collapseAll) { this.collapseAll = false; }
            if (collapse !== this.collapseAll) {
                let fl = false;
                for (let i =0; i < this.treeData.length; i++) {
                    if (this.treeData[i].collapse !== collapse) {
                        fl = true;
                        break;
                    }
                }
                if (!fl) { this.collapseAll = collapse; }
            }
            for (const clssEl of this.treeData[katIndx].child_list) {
                clssEl.collapse = collapse;
                for (const pclEl of clssEl.child_list) {
                    if (pclEl.collapse!==undefined) { pclEl.collapse = collapse; }
                }
            }
        } else {
            if (pclIndx === null) {
                this.treeData[katIndx].child_list[clssIndx].collapse = collapse;
                for (const pclEl of this.treeData[katIndx].child_list[clssIndx].child_list) {
                    if (pclEl.collapse!==undefined) { pclEl.collapse = collapse; }
                }
            } else {
                if (this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].collapse!==undefined) { this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].collapse = collapse; }
            }
         }
         this.treeData.push({});
         this.treeData.splice(this.treeData.length-1, 1);
    }

    private mounted() {
        this.getTreeData();
        this.$watch('tableData', () => { this.getTreeData(); }, { deep: true });
    }

    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }


    private async nextInput(monthIndx: number, katIndx: number, clssIndx: number, pclIndx: number, spfIndx: number) {
        let indxObj: any = { katIndx, clssIndx, pclIndx, spfIndx };
        // @ts-ignore
        if (this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].child_list.length - 1 > spfIndx) {
            if (indxObj.spfIndx!==null) { indxObj.spfIndx++; }
        } else {
            indxObj = this.getNextInputIndx(katIndx, clssIndx, pclIndx, spfIndx);
        }

        this.treeData[indxObj.katIndx].collapse = false;
        this.treeData[indxObj.katIndx].child_list[indxObj.clssIndx].collapse = false;
        const name = `inp_${indxObj.katIndx}_${indxObj.clssIndx}_${indxObj.pclIndx}_${indxObj.spfIndx}_month${monthIndx}`;
        this.treeData[indxObj.katIndx].child_list[indxObj.clssIndx].child_list[indxObj.pclIndx].collapse = false;
        await this.$nextTick();
        // eslint-disable-next-line
        // @ts-ignore
        if (this.$refs[name]) { this.$refs[name][0].select(); }
        if (!(this.selectedRow && this.selectedRow.katIndx === indxObj.katIndx && this.selectedRow.clssIndx === indxObj.clssIndx && this.selectedRow.pclIndx === indxObj.pclIndx && this.selectedRow.spfIndx === indxObj.spfIndx)) {
            this.selectRow(indxObj.katIndx, indxObj.clssIndx, indxObj.pclIndx, indxObj.spfIndx);
        }
    }

    // private getNextInputIndx(prgIndx: number, subIndx: number, spfIndx: number, chldIndx: number | null) {
    private getNextInputIndx(katIndx: number, clssIndx: number, pclIndx: number, spfIndx: number) {
        let newKatIndx = katIndx;
        let newClssIndx = clssIndx;
        let newPclIndx = pclIndx;
        let newSpfIndx = 0;
        if (this.treeData[katIndx].child_list[clssIndx].child_list.length-1 > pclIndx){
            newPclIndx++;
        } else {
            if (this.treeData[katIndx].child_list.length - 1 > clssIndx) {
                newClssIndx++;
                newPclIndx = 0;
                newSpfIndx = 0;
            } else {
                if (this.treeData.length - 1 > katIndx) {
                    newKatIndx++;
                    newClssIndx = 0;
                    newPclIndx = 0;
                    newSpfIndx = 0;
                } else {
                    newKatIndx = 0;
                    newClssIndx = 0;
                    newPclIndx = 0;
                    newSpfIndx = 0;
                }
            }
        }
        return { katIndx: newKatIndx, clssIndx: newClssIndx, pclIndx: newPclIndx, spfIndx: newSpfIndx };
    }

    private chgValue(spfObj: any, fieldName: string) {
        if (parseFloat(String(spfObj[fieldName])).isNaN) { spfObj[fieldName] = 0; }
        spfObj[fieldName] = parseInt((spfObj[fieldName] * 10).toString()) / 10;
        const tableData = this.treeData.slice();
        tableData[spfObj._katIndx].child_list[spfObj._clssIndx].child_list[spfObj._pclIndx].child_list[spfObj._spfIndx][fieldName] = spfObj[fieldName];
        this.$emit('chgData', tableData);
    }

    private selectRow(katIndx: number, clssIndx: number, pclIndx: number, spfIndx: number) {
        if (this.selectedRow) {
            const obj = this.treeData[this.selectedRow.katIndx].child_list[this.selectedRow.clssIndx].child_list[this.selectedRow.pclIndx].child_list[this.selectedRow.spfIndx];
            obj.selectedRow = null;
            /* if (this.selectedRow.katIndx === katIndx && this.selectedRow.clssIndx === clssIndx && this.selectedRow.pclIndx === pclIndx && this.selectedRow.spfIndx === spfIndx) {
                this.selectedRow = null;
                obj.selectedRow = null;
                return;
            } */
        }
        const obj = this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].child_list[spfIndx];
        obj.selectedRow = true;
        const selectedRow = Object.assign({ katIndx: katIndx, clssIndx: clssIndx, pclIndx: pclIndx, spfIndx: spfIndx }, obj);
        let sum = 0;
        for (const monthName of monthLst) {
            sum += this.sumMonth(selectedRow, monthName);
        }
        selectedRow.sumMonth = Math.round(sum * 10) / 10;

        this.calcSumSelectedAndSpf(selectedRow);
        this.selectedRow = selectedRow;
    }

    private calcSumSelectedAndSpf(selectedRow: any) { // расчёт суммы планового значения и специфики (например january и month0)
        let sumMonthTmp = selectedRow.sumMonth;
        for (let i=0; i < this.editedArr.length; i++) {
            const obj = this.treeData[selectedRow.katIndx].child_list[selectedRow.clssIndx].child_list[selectedRow.pclIndx].child_list[selectedRow.spfIndx];
            if (this.editedArr[i]) {
                selectedRow[`${monthLst[i]}`] = Math.round((selectedRow[monthLst[i]] + obj[`month${i}`]) * 10) / 10;
                selectedRow[`${monthLst[i]}_tmp`] = Math.round((selectedRow[monthLst[i]]) * 10) / 10;
                sumMonthTmp += obj[`month${i}`];
            }
        }
        selectedRow.sumMonthTmp = Math.round(sumMonthTmp * 10)/10;
    }

    private sumMonth(monthObj: any, fieldName: string) {
        if (!monthObj[fieldName]) { monthObj[fieldName] = 0; }
         return monthObj[fieldName];
    }

    private inputFocus(event: any) {
        event.target.select();
    }
}
