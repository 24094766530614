<template>
    <div>
        <div v-if="!newForm">
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown overflow-visible" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <div class="filter-block-date-range">
                                <label class="mr-3">Период с</label>
                                <date-picker
                                    placeholder="Выберите дату"
                                    v-model="dateFromForInput"
                                    format="DD/MM/YYYY"
                                    :append-to-body="false"
                                />
                                <label class="mx-3">по</label>
                                <date-picker
                                    placeholder="Выберите дату"
                                    v-model="dateToForInput"
                                    format="DD/MM/YYYY"
                                    :append-to-body="false"
                                />
                            </div>
                            <div class="sp-block-alteration">
                                <!---------------------Регион---------------------->
                                <div class="filter-block">
                                    <b-form-group label="Область/район">
                                        <multiselect
                                            v-model="currRegion"
                                            label="localText"
                                            placeholder="Выберите регион"
                                            :options="regionBase"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            ref="filterReg"
                                        />
                                    </b-form-group>
                                </div>
                                <div class="filter-block">
                                    <b-button variant="primary" @click="loadData(year, currRegion)">Применить</b-button>
                                    <!--                                <b-button variant="secondary" @click="clearParam">Сбросить</b-button>-->
                                </div>
                                <hr>
                                <!------------------Вид заявки--------------------->
                                <div class="filter-block">
                                    <b-form-group label="Вид заявки">
                                        <multiselect
                                            v-model="selectedAlteration"
                                            placeholder="Выберите вид заявки"
                                            :options="listAlteration"
                                            track-by="name_ru"
                                            label="name_ru"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            ref="refAlteration"
                                        >
                                        </multiselect>
                                    </b-form-group>
                                </div>
                                <!------------------Статус------------------------->
                                <div class="filter-block">
                                    <b-form-group label="Статус">
                                        <multiselect
                                            v-model="selectedStatus"
                                            placeholder="Выберите статус"
                                            :options="listStatus"
                                            track-by="name_ru"
                                            label="name_ru"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            ref="refStatus"
                                        >
                                        </multiselect>
                                    </b-form-group>
                                </div>
                                <div class="filter-block">
                                    <b-button variant="primary" @click="filterApply">Применить</b-button>
                                    <b-button variant="secondary" @click="clearFilter">Сбросить</b-button>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                </div>
            </div>
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="year" @click="openFilterByRef('filterYear')"> {{ year }} </span>
                <span class="item-breadcrumb" v-if="currRegion" @click="openFilterByRef('filterReg')"> {{ currRegion.localText}} </span>
                <span class="item-breadcrumb" v-if="selectedAlteration" @click="openFilterByRef('refAlteration')"> Тип - {{selectedAlteration.name_ru}} </span>
                <span class="item-breadcrumb" v-if="selectedStatus" @click="openFilterByRef('refStatus')"> Статус - {{selectedStatus.name_ru}} </span>
            </div>
            <CRequestsTable
                :requestsBase="requestsBase"
                @onChange="onChange"
                @onOpen="onOpen"
                @onDelete="onDelete"
                @chgData="chgData"
                @onChecked="onChecked"
                @reLoad="onReLoad"
                :levelsCheck="false"
                :incTab="true"
                :requestTypeOptions="requestTypeOptions"
                :accessLevel="accessLevels"
                :activeTab="activeTab"
                :accessLevels="accessLevels"
                :statusType="listStatus"
                :edit-access-status-list="[1, 27, 69]"
                :tabIndex="tabIndex"
            />
            <b-modal
                size="lg"
                id="modal-creates"
                v-model="modalForm"
                centered
                hide-header
                hide-footer
            >
                <div class="add_modal">
                    <div class="add_modal__title">Создать</div>

                    <div class="add_modal__body">
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">№</div>
                            <div class="date-picker-container">
                                <b-form-input
                                    type="number"
                                    :min="1"
                                    class="form-input"
                                    v-model="currentAlteration.number"
                                    @keypress="keyPress($event, '^\\d*\\d{0,9}$')"
                                    @change="saveFalse = false"
                                />
                                Дата
                                <date-picker
                                    placeholder="Выберите дату"
                                    v-model="curDate"
                                    format = "DD/MM/YYYY"
                                    type="date"
                                    style="max-width: 500px"
                                    @input="dateChange"
                                />

                            </div>
                        </div>
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">Вид заявки</div>
                            <multiselect
                                v-model="selectedReqType"
                                label="localText"
                                placeholder="Выбрать вид заявки"
                                :options="requestTypeList"
                                style="width: 480px"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="reqTypeClick"
                                ref="filterAbp"
                            />
                        </div>
                        <div class="add_modal__item">
                            <div class="add_modal__item__title black">На основании:</div>
                            <b-form-input
                                id="textarea"
                                placeholder="Добавить описание..."
                                type="text"
                                v-model="currentAlteration.description"
                                class="input"
                                style="width: 480px"
                            ></b-form-input>

                        </div>
                        <div class="add_modal__item">
                            <label class="input-file-label"
                            >Прикрепите документ</label
                            >
                            <b-form-file v-model="file"
                                         id="fileInput"
                                         accept=".xls, .xlsx, .pdf, .doc, .docx"
                                         placeholder=""
                                         browse-text = "Обзор"
                                         @change="syncFile"
                                         size="sm"
                                         style="width: 760px" >
                            </b-form-file>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-striped"
                                   style="font-weight: 600;
                                          font-size: 14px;
                                          color: #6087a0;">
                                <tr v-for="(file, index) in createdAlterationFiles" :key="'index'+index">
                                    <td>{{file.name}}</td>
                                    <td><button class="button button-close"
                                                @click="deletedAlterationFiles.push(file); createdAlterationFiles.splice(index, 1);">x</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div v-if="saveTrue">
                        <p style="color: red;  font-size: 14px;">{{typeName}}</p>
                    </div>
                    <div v-if="saveFalse">
                        <h6 style="color: red; font-size: 14px;">{{ numberName }}</h6>
                    </div>
                    <div class="add_modal__footer">
                        <b-button variant="primary"  @click="saveRequest">Сохранить</b-button>
                        <b-button variant="secondary"  @click="onClose">Отменить</b-button>
                    </div>
                </div>
            </b-modal>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
        <div v-if="newForm">
            <new-alteration-form :currentAlteration="currentAlteration"
                                 :currAbp="currAbp"
                                 :currGu="currGu"
                                 :currRegion="currRegion"
                                 :guBase="guBase"
                                 :listAlteration="listAlteration"
                                 :accessLevel="accessLevels"
                                 :moduleCode="moduleCode"
                                 :mode="mode"
                                 :tableType="2"
                                 @onBack="onBack"
                                 :tab-index="tabIndex"
                                 :activeTab="activeTab">
            </new-alteration-form>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import CRequestsTable from "@/modules/budget/alteration/form/tabs/c-requests-table";
import VueElementLoading from "vue-element-loading";
import newAlterationForm from "@/modules/budget/alteration/form/tabs/components/CreateForm/newAlterationForm";
import DatePicker from "vue2-datepicker";
import store from "@/services/store";

export default {
    name: "AlterationApplications",
    components: { CRequestsTable, 'loading': VueElementLoading, newAlterationForm, DatePicker },
    props: ["requestTypeOptions", "userId", "moduleCode", "regionBase", "yearList", "activeTab", "tabIndex"],
    watch: {},
    data() {
        return {
            loading: false,
            year: `${new Date().getFullYear()}`,
            selectedAlteration: null,
            currAltStatusEdit: false,
            currAbp: null,
            abpBase: [],
            currGu: null,
            guBase: [],
            guArr: [],
            accessLevels: {},
            currRegion: null,
            selectedStatus: null,
            requestsBase: null,
            requestsData: null,
            newForm: false,
            listAlteration: [],
            listStatus: [],
            currentAlteration: {
                id: null,
                number: null,
                gu: null,
                date: new Date(),
                requestType: null,
                budgetVersion: null,
                planType: null,
                requestLevel: null,
                user_id: null,
                create_date: null,
                update_date: null,
                delete_date: null,
                fileList: []
            },
            selectedReqType: null,
            requestTypeList: [],
            cDis: true,
            modalForm: false,
            deletedAlterationFiles: [],
            createdAlterationFiles: [],
            saveTrue: false,
            saveFalse: false,
            file: null,
            userList: [],
            dateFromForInput: null,
            dateToForInput: null,
            dateFrom: null,
            dateTo: null,
            curDate: new Date(`${new Date()}`)
        }
    },
    computed: {
        mode() {
            return 'exi';
        },
        numberName() {
            return 'Заявка с № ' + this.currentAlteration.number + ' уже существует';
        },
        typeName() {
            let name = ''
            if (this.currentAlteration.description === null) {
                name = name + '"Основание"';
            }
            if (this.currentAlteration.requestType === null) {
                if (name.length > 0) { name = name + ' , '}
                name = name + '"Вид заявки"';
            }
            if (this.currentAlteration.number === '' || this.currentAlteration.number === null) {
                if (name.length > 0) { name = name + ' , '}
                name = name + '"№"';
            }
            return 'Необходимо заполнить обязательные поля: ' + name;
        }
    },
    async mounted() {
        this.loading = true;
        this.dateFromForInput = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}` + '-01');
        this.dateToForInput = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${this.getLastDayOfMonth(new Date().getMonth() + 1, new Date().getDate())}`)
        await this.getAccess();
        await this.loadStatusList();
        this.currRegion = this.regionBase[0];
        if (this.currRegion !== undefined) {
            await this.loadData(this.year, this.currRegion);
        }
        this.typeListLoad();
        this.selectedAlteration = this.listAlteration[0];
        this.loading = false;
    },
    methods: {
        getLastDayOfMonth(year, month) {
            const date = new Date(year, month + 1, 0);
            return date.getDate();
        },
        async getAccess() {
            try {
                const result = await fetch(`/api-py/get-access-info/${this.moduleCode}/${this.userId}`);
                if (result.status === 200) {
                    const res = await result.json();
                    if (res.id > 0) {
                        res.editAccess = res['access_level'] !== 1;
                        this.accessLevels = res;
                    }
                } else {
                    this.makeToast('danger', 'get-access', `${result.status} - ${result.statusText}`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-access', error.toString());
            }
        },
        async loadStatusList() {
            let response = [];
            try {
                this.listStatus.splice(0);
                response = await fetch('/api-py/get-full-status-list/' + this.mode);
                this.listStatus = await response.json();
                this.selectedStatus = this.listStatus[0];
            } catch (error) {
                response = [];
                this.makeToast("danger", "Ошибка загрузки статусов", error.toString());
            }
        },
        chgData(data) {
            try {
                this.requestsBase = data;
            } catch (error) {
                this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
            }
        },
        onChecked(val) {
            this.cDis = !val > 0;
        },
        clearParam() {
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'filterYear':
                    refItem = this.$refs.filterYear;
                    break;
                case 'filterReg':
                    refItem = this.$refs.filterReg;
                    break;
                case 'refAlteration':
                    refItem = this.$refs.refAlteration;
                    break;
                case 'refStatus':
                    refItem = this.$refs.refStatus;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        onBack(val) {
            this.newForm = val;
            this.loadData(this.year, this.currRegion);
        },
        onChange(val) {
            this.currentAlteration = Object.assign({}, val);
            this.modalConsolOpen(this.currentAlteration);
        },
        onOpen(val) {
            this.currentAlteration = val;
            this.newForm = true;
        },
        onDelete(val) {
            if (val) {
                this.deleteRequest(val);
            }
        },
        onReLoad(val){ this.$emit('reLoad', val); },
        deleteRequest(item) {
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление данной записи...',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        fetch("/api/budget-execution-alteration-uf-request?requestId=" + item.id, {
                            method: "DELETE",
                        });
                        const ind1 = this.requestsData.indexOf(item);
                        this.requestsData.splice(ind1, 1);

                        setTimeout(() => this.$emit('reLoad', true), 1000);
                        // const ind2 = this.requestsBase.indexOf(item);
                        // this.requestsBase.splice(ind2, 1);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },
        async loadData(year, region) {
            let response = [];
            const dateFrom = this.dateFromForInput.toLocaleDateString().split('.').reverse().join('-');
            const dateTo = this.dateToForInput.toLocaleDateString().split('.').reverse().join('-');
            if ((year || region)) {
                try {
                    response = await fetch(
                        `/api/budget-execution-alteration-uf-request/list?region=${region.code}&dateBegin=${dateFrom}&dateEnd=${dateTo}`
                    );
                    response = await response.json();
                    await this.getData(response);
                } catch (error) {
                    response = [];
                    this.makeToast(
                        "danger",
                        "Ошибка загрузки заключений",
                        error.toString()
                    );
                }
            }
        },
        async getData(data) {
            this.loading = true;
            this.requestsData = data.sort((a, b) => b.create_date - a.create_date);
            this.addUserName(this.requestsData);
            for (const item of this.requestsData) {
                // if (item.status.status === 39) {
                //     const param = {requestId: item.id};
                //     const response = await fetch('/api-py/check-39-status/' + JSON.stringify(param));
                //     const statusObject = await response.json();
                //     console.log(statusObject);
                //     item.childStatus = statusObject.childStatus;
                //     item.numberArr = statusObject.numberArr;
                // }
            }
            this.requestsBase = this.requestsData;
            this.loading = false;
        },
        addUserName(data) {
            const userIdList = [];
            data.forEach((item) => {userIdList.push(item.user_id);});
            const unicUserIdList = [...new Set(userIdList)];
            this.loadUsers(unicUserIdList);
        },
        async loadUsers(arr) {
            try {
                for (const item of arr) {
                    const response = await fetch('/api-py/get-realm-user/' + item);
                    const currUser = await response.json();
                    if (currUser !== undefined){
                        this.userList.push(currUser);
                    }
                }
                this.getUsers();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки пользователей', error.toString());
            }
        },
        getUsers() {
            if (!this.requestsBase) return;
            this.requestsBase.forEach((item) => {
                this.userList.map((user) => {
                    if ((user !== null) && (user.id === item.user_id)) {
                        const userName = `${user.firstName} ${user.lastName}`;
                        this.$set(item, "userName", userName);
                    }
                });
            });
        },
        altAllAbp(val) {
            this.currentAlteration = val;
            this.newForm = true;
        },
        altApplAbp(val) {
            this.currentAlteration = val;
            this.newForm = true;
        },
        async modalConsolOpen(item) {
            this.currAltStatusEdit = true;
            const arr = {
                id: item.id,
                number: item.number,
                gu: item.gu,
                abp: item.abp,
                date: item.date,
                requestType: item.requestType,
                description: item.description,
                user_id: item.user_id,
                level: item.level,
                fileList: item.fileList
            };
            this.currentAlteration = arr;
            this.currentAlteration.date = new Date(this.currentAlteration.date);
            this.curDate = new Date(this.currentAlteration.date);
            this.dateFrom = new Date(`${this.curDate.getFullYear()}-${this.curDate.getMonth() + 1}` + '-01');
            this.dateTo = new Date(`${this.curDate.getFullYear()}-${this.curDate.getMonth() + 1}-${new Date(this.curDate.getFullYear(), this.curDate.getMonth() + 1, 0).getDate()}`)
            if (this.currentAlteration.region === null) {
                this.currentAlteration.region = this.currRegion.code;
            }
            if (this.currentAlteration.user_id === null) {
                this.currentAlteration.user_id = this.userId;
            }
            this.selectedReqType = null;
            for (const item of this.requestTypeOptions) {
                if (item.code === this.currentAlteration.requestType) {
                    this.selectedReqType = item;
                }
            }

            this.createdAlterationFiles = [];
            for (const file of this.currentAlteration.fileList) {
                this.createdAlterationFiles.push({...file});
            }
            if (this.createdAlterationFiles.length !== 0) {
                for (let i = 0; i < this.createdAlterationFiles.length; i++) {
                    this.createdAlterationFiles[i].old = true;
                }
                this.currentAlteration.fileList = null;
            }
            this.modalForm = true
        },
        typeListLoad() {
            this.listAlteration.splice(0);
            this.listAlteration.push({code: 'all', name_ru: 'Все'});
            if (this.requestTypeOptions && this.requestTypeOptions.length > 0) {
                this.requestTypeOptions.forEach((item) => {
                    if (!['individual', 'budget_income', 'budget_income_clarification', 'budget_income_movement'].includes(item.code)) {
                        this.listAlteration.push(item);
                        this.requestTypeList.push(item);
                    }
                });
            }
        },
        onClose() {
            this.modalForm = false;
            if (this.currentAlteration.hasOwnProperty('oldDate')) {
                this.$set(this.currentAlteration, 'date', this.currentAlteration.oldDate);
            }
        },
        async saveRequest() {
            this.saveTrue = false;
            if (this.currentAlteration.description !== null && this.currentAlteration.requestType !== null && this.currentAlteration.number !== '') {
                this.loading = true;
                await this.svRequest();
            } else {
                this.saveTrue = true;
            }
        },
        syncFile: function (e) {
            e.preventDefault();
            this.createdAlterationFiles.push(e.target.files[0]);
            const files = document.getElementById('fileInput').value;
        },
        async svRequest() {
            this.currentAlteration.date = this.currentAlteration.date.getTime();
            this.currentAlteration.region = this.currRegion.code;
            const prm = "/api/budget-execution-alteration-uf-request";
            await fetch(prm,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: this.currentAlteration.id ? "PUT" : "POST",
                    body: JSON.stringify(this.currentAlteration)
                })
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(async json => {
                            this.currentAlteration = json;
                            this.currentAlteration.new = !this.currAltStatusEdit;
                            if (!this.currentAlteration.status) {
                                this.$set(this.currentAlteration, "status", []);
                                if (!this.currentAlteration.status.status) {
                                    this.$set(this.currentAlteration.status, "status", 1);
                                }
                            }
                            this.currentAlteration = {};
                            for (let i = 0; i < this.createdAlterationFiles.length; i++) {
                                if (!this.createdAlterationFiles[i].old) {
                                    await this.saveFile(json.id, this.createdAlterationFiles[i])
                                }
                            }                            // Добавить заявку Гу в заявку Абп
                            for (let i = 0; i < this.deletedAlterationFiles.length; i++) {
                                await this.deleteFile(this.deletedAlterationFiles[i].id, this.deletedAlterationFiles[i])
                            }
                            this.reset();
                            this.loading = false;
                            // this.newForm = true;
                        });
                        this.modalForm = false;
                    } else {
                        this.loading = false;
                    }
                    if (response.status === 500) {
                        response.json().then(async json => {
                            if (json.key === 'modules.budgetExecutionAlteration.error.requestNumberAlreadyExists') {
                                this.saveFalse = true;
                                this.loading = false;
                            }
                            this.currentAlteration.date = new Date(this.currentAlteration.date);
                        })
                    }
                })
        },
        saveFile: async function (id, file) {
            const data = new FormData();
            data.append("file", file);
            return await fetch("/api/budget-execution-alteration-uf-request/file?requestId=" + id,
                {
                    method: "POST",
                    body: data
                })
                .then(response => {
                })
                .catch(error => {
                    alert("Error!");
                    console.log(error);
                });
        },
        deleteFile: async function (id) {
            return await fetch("/api/budget-execution-alteration-uf-request/file?fileId=" + id,
                {
                    method: "DELETE",
                })
        },
        reqTypeClick() {
            this.currentAlteration.requestType = this.selectedReqType.code;
        },
        reset: function() {
            this.deletedAlterationFiles = [];
            this.loadData(this.year, this.currRegion);
        },
        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            this.saveFalse = false
        }, // ввод по заданному паттерну,
        dateChange(){
            if (this.currAltStatusEdit){
                if (this.curDate >= this.dateFrom && this.curDate <= this.dateTo) {
                    this.$set(this.currentAlteration, 'oldDate', this.currentAlteration.date);
                    this.currentAlteration.date = this.curDate;
                } else {
                    this.curDate = this.currentAlteration.date;
                }
            } else { this.currentAlteration.date = this.curDate; }
        },
        filterApply(){
            if (this.requestsData !== null) {
                this.requestsBase = this.requestsData;
                if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
                    this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
                }
                if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
                    this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
                }
            }
        },
        clearFilter(){
            this.selectedAlteration = this.listAlteration[0];
            this.selectedStatus = this.listStatus[0];
            this.requestsBase = this.requestsData;
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    gap: 20px;
    background-color: #eef1f8;
    justify-content: space-between;
}

.content {
    height: 100%;
    .title {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 20px;
        margin-bottom: 26px;
    }
    .constructor,
    .forms {
        flex: 1;
    }

    .body {
        background-color: #f7f9fc;
    }

    .item {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .multiselect {
        max-width: 400px;
    }

    .footer {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: end;
        border: 1px solid #d3dbe3;
        border-top: 0;
        padding-right: 40.5%;
    }

    .vertical-line {
        width: 1px;
        background-color: #d3dbe3;
        margin-top: 40px;
    }
}
.add_modal {
    width:100%;
    height:100%;
    &__body {
        background-color: #f7f9fc;
        border: 1px solid #d3dbe3;
        border-radius: 5px;
        margin-bottom: 23px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .input-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
        &-label {
            width: 400px;
            font-weight: 600;
            font-size: 14px;
            color: #6087a0;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
        }
    }
    .form-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
    }
}
.button {
    border: none;
    border-radius: 10px;
}

.button-close {
    color: red;
}
.second-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 26px;
    &__title {
        font-weight: 500;
        font-size: 14px;
    }
}

.date-picker-container {
    display: flex;
    gap: 79px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6087a0;

    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
}

</style>