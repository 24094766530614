<template>
    <div>
        <div v-if="!newForm">
            <div>
                <div class="filter-container">
                    <div class="left-content">
                        <b-dropdown class="filter-dropdown overflow-visible" variant="default" ref="drop">
                            <template #button-content>
                                <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                                class="icon icon-keyboard"></i></span>
                            </template>
                            <div>
                                <div class="top-content">
                                    <span>Параметры фильтра</span>
                                    <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                                </div>
                                <div class="filter-block-date-range">
                                    <label class="mr-3">Период с</label>
                                    <date-picker
                                        placeholder="Выберите дату"
                                        v-model="dateFromForInput"
                                        format="DD/MM/YYYY"
                                        :append-to-body="false"
                                    />
                                    <label class="mx-3">по</label>
                                    <date-picker
                                        placeholder="Выберите дату"
                                        v-model="dateToForInput"
                                        format="DD/MM/YYYY"
                                        :append-to-body="false"
                                    />
                                </div>
                                <!---------------------АБП------------------------->
                               <div class="sp-block-alteration">
                                   <div class="filter-block m-0">
                                       <b-form-group label="АБП" v-if="['abp', 'uf'].includes(accessLevels.position)">
                                           <multiselect
                                               v-model="currAbp"
                                               label="localText"
                                               placeholder="Выберите АБП"
                                               :options="abpBase"
                                               :searchable="true"
                                               :allow-empty="false"
                                               :show-labels="false"
                                               ref="filterAbp"
                                           >
                                           </multiselect>
                                       </b-form-group>
                                   </div>
                                   <!---------------------Регион---------------------->
                                   <div class="filter-block">
                                       <b-form-group label="Область/район">
                                           <multiselect
                                               v-model="currRegion"
                                               label="localText"
                                               placeholder="Выберите регион"
                                               :options="regionBase"
                                               :searchable="true"
                                               :allow-empty="false"
                                               :show-labels="false"
                                               ref="filterReg"
                                           />
                                       </b-form-group>
                                   </div>
                                   <!------------------Кнопка------------------------->
                                   <div class="filter-block">
                                       <b-button variant="primary" @click="loadData(year, currRegion, currAbp)">Применить</b-button>
                                       <b-button variant="secondary" @click="clearParam">Сбросить</b-button>
                                   </div>
                                   <hr>
                                   <!------------------Вид заявки--------------------->
                                   <div class="filter-block">
                                       <b-form-group label="Вид заявки">
                                           <multiselect
                                               v-model="selectedAlteration"
                                               placeholder="Выберите вид заявки"
                                               :options="listAlteration"
                                               track-by="name_ru"
                                               label="name_ru"
                                               :searchable="true"
                                               :allow-empty="false"
                                               :show-labels="false"
                                               ref="refAlteration"
                                           >
                                           </multiselect>
                                       </b-form-group>
                                   </div>
                                   <!------------------Статус------------------------->
                                   <div class="filter-block">
                                       <b-form-group label="Статус">
                                           <multiselect
                                               v-model="selectedStatus"
                                               placeholder="Выберите статус"
                                               :options="listStatus"
                                               track-by="name_ru"
                                               label="name_ru"
                                               :searchable="true"
                                               :allow-empty="false"
                                               :show-labels="false"
                                               ref="refStatus"
                                           >
                                           </multiselect>
                                       </b-form-group>
                                   </div>
                                   <div class="filter-block">
                                       <b-button variant="primary" @click="filterApply">Применить</b-button>
                                       <b-button variant="secondary" @click="clearFilter">Сбросить</b-button>
                                   </div>
                                   <hr>
                               </div>
                            </div>
                        </b-dropdown>
                    </div>

                    <div class="right-content">
                        <div class="filter-actions" v-if="levels.length > 1">
                            <b-form-radio-group
                                id="radio-group-1"
                                v-model="level"
                                :options="levels"
                                name="level-options"
                                @change="changeLevel"
                            >
                            </b-form-radio-group>
                        </div>
                        <div class="filter-actions">
                            <b-button :disabled="cDis" v-if="accessLevels.editAccess" variant="primary" @click="modalConsolOpen(null)">
                                <span>Сконсолидировать</span>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <!--  хлебные крошки   -->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="year" @click="openFilterByRef('filterYear')"> {{ year }} </span>
                <span class="item-breadcrumb" v-if="currAbp && !['uf'].includes(accessLevels.position)" @click="openFilterByRef('filterAbp')"> {{currAbp.abp}}-АБП </span>
                <span class="item-breadcrumb" v-if="currRegion" @click="openFilterByRef('filterReg')"> {{ currRegion.localText}} </span>
                <span class="item-breadcrumb" v-if="selectedAlteration" @click="openFilterByRef('refAlteration')"> Тип - {{selectedAlteration.name_ru}} </span>
                <span class="item-breadcrumb" v-if="selectedStatus" @click="openFilterByRef('refStatus')"> Статус - {{selectedStatus.name_ru}}  </span>
            </div>
            <CRequestsTable
                :requestsBase="requestsBase"
                @onChange="onChange"
                @onOpen="onOpen"
                @onDelete="onDelete"
                @chgData="chgData"
                @onChecked="onChecked"
                @reLoad="onReLoad"
                :levelsCheck="true"
                :requestTypeOptions="requestTypeOptions"
                :accessLevels="accessLevels"
                :activeTab="activeTab"
                :statusType="listStatus"
                :inc-tab="true"
                :edit-access-status-list="typeForm === 0? [1, 27, 69]: [1, 28, 69]"
                :tabIndex="tabIndex"
            />
            <b-modal
                size="lg"
                id="modal-create2"
                v-model="modalForm"
                centered
                hide-header
                hide-footer
            >
                <div class="add_modal">
                    <div class="add_modal__title" v-if="requestStatus">Редактировать</div>
                    <div class="add_modal__title" v-if="!requestStatus">Создать</div>

                    <div class="add_modal__body">
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">№</div>
                            <div class="date-picker-container">
                                <b-form-input
                                    type="number"
                                    :min="1"
                                    class="form-input"
                                    v-model="currentAlteration.number"
                                    @keypress="keyPress($event, '^\\d*\\d{0,9}$')"
                                    @change="saveFalse = false"
                                />
                                Дата
                                <date-picker
                                    placeholder="Выберите дату"
                                    v-model="curDate"
                                    format = "DD/MM/YYYY"
                                    type="date"
                                    style="max-width: 500px"
                                    @input="dateChange"
                                />

                            </div>
                        </div>
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">Вид заявки</div>
                            <multiselect
                                v-model="selectedReqType"
                                label="localText"
                                placeholder="Выбрать вид заявки"
                                :options="requestTypeList"
                                style="width: 480px"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="reqTypeClick"
                                ref="filterAbp"
                            />
                        </div>
                        <div class="add_modal__item">
                            <div class="add_modal__item__title black">На основании:</div>
                            <b-form-input
                                id="textarea"
                                placeholder="Добавить описание..."
                                type="text"
                                v-model="currentAlteration.description"
                                class="input"
                                style="width: 480px"
                            ></b-form-input>

                        </div>
                        <div class="add_modal__item">
                            <label class="input-file-label"
                            >Прикрепите документ</label
                            >
                            <b-form-file v-model="file"
                                         id="fileInput"
                                         accept=".xls, .xlsx, .pdf, .doc, .docx"
                                         placeholder=""
                                         browse-text = "Обзор"
                                         @change="syncFile"
                                         size="sm"
                                         style="width: 760px" >
                            </b-form-file>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-striped"
                                   style="font-weight: 600;
                                          font-size: 14px;
                                          color: #6087a0;">
                                <tr v-for="(file, index) in createdAlterationFiles" :key="'index'+index">
                                    <td>{{file.name}}</td>
                                    <td><button class="button button-close"
                                                @click="deletedAlterationFiles.push(file); createdAlterationFiles.splice(index, 1);">x</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div v-if="saveTrue">
                        <p style="color: red;  font-size: 14px;">{{typeName}}</p>
                    </div>
                    <div v-if="saveFalse">
                        <p style="color: red; font-size: 14px;">{{ numberName }}</p>
                    </div>
                    <div class="add_modal__footer">
                        <b-button variant="primary"  @click="saveRequest">Сохранить</b-button>
                        <b-button variant="secondary"  @click="onClose">Отменить</b-button>
                    </div>
                </div>
            </b-modal>
            <b-modal
                size="lg"
                v-model="closingFirstModal"
                @hide="handleCloseSecondModal"
                centered
                hide-header
                hide-footer
            >
                <div class="second-modal">
                    <div class="second-modal__title">Сохранить измения?</div>
                    <div class="buttons">
                        <b-button variant="secondary" @click="handleCloseSecondModal"
                        >Нет</b-button
                        >
                        <b-button variant="success" @click="handleCloseSecondModal"
                        >Да</b-button
                        >
                    </div>
                </div>
            </b-modal>
        </div>
        <div v-if="newForm">
            <new-alteration-form :currentAlteration="currentAlteration"
                                 :currAbp="currAbp"
                                 :currGu="null"
                                 :currRegion="currRegion"
                                 :guBase="guBase"
                                 :listAlteration="listAlteration"
                                 :accessLevel="accessLevels"
                                 :moduleCode="moduleCode"
                                 :mode="mode"
                                 :newRequest="false"
                                 :tableType="1"
                                 @onBack="onBack"
                                 :tab-index="tabIndex"
                                 :activeTab="activeTab">
            </new-alteration-form>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import CRequestsTable from "./c-requests-table.vue";
import VueElementLoading from "vue-element-loading";
import newAlterationForm from "@/modules/budget/alteration/form/tabs/components/CreateForm/newAlterationForm";
import DatePicker from "vue2-datepicker";
import store from "@/services/store";

export default {
    name: "AlterationReferencesABP",
    components: { CRequestsTable, DatePicker, 'loading': VueElementLoading, newAlterationForm },
    data() {
        return {
            year: `${new Date().getFullYear()}`,
            accessLevels: {},
            selectedAlteration: null,
            selectedStatus: null,
            listStatus: [],
            listAlteration: [],
            loading: false,
            modalForm: false,
            showBr: false,
            selectedReqType: null,
            requestTypeList: [],
            currAbp: null,
            abpBase: [],
            guBase: [],
            guArr: [],
            currRegion: null,
            requestsBase: null,
            requestsData: null,
            userAbp: null,
            closingFirstModal: false,
            files: [],
            currAltStatusEdit: false,
            currentAlteration: {
                id: null,
                number: null,
                gu: null,
                date: new Date(),
                requestType: null,
                budgetVersion: null,
                planType: null,
                requestLevel: null,
                user_id: null,
                create_date: null,
                update_date: null,
                delete_date: null,
                fileList: []
            },
            createdAlterationFiles: [],
            editedAlterationFiles: [],
            deletedAlterationFiles: [],
            newForm: false,
            file: null,
            saveTrue: false,
            saveFalse: false,
            variantBase: [],
            currentVariant: null,
            cDis: true,
            requestStatus: false,
            level: 1,
            levels: [],
            userList: [],
            dateFromForInput: null,
            dateToForInput: null,
            dateFrom: null,
            dateTo: null,
            curDate: new Date(`${new Date()}`),
            lsStatusFilter: 'budget_alteration_tab3_status'
        }
    },
    computed: {
        mode() {
            switch (this.typeForm) {
                case 1:
                    return 'exz';
                    break;
                default:
                    return 'exv';
                    break;
            }
        },
        numberName() {
            return 'Заявка с № ' + this.currentAlteration.number + ' уже существует';
        },
        typeName() {
            let name = ''
            if (this.currentAlteration.description === null) {
                name = name + '"Основание"';
            }
            if (this.currentAlteration.requestType === null) {
                if (name.length > 0) { name = name + ' , '}
                name = name + '"Вид заявки"';
            }
            if (this.currentAlteration.number === '' || this.currentAlteration.number === null) {
                if (name.length > 0) { name = name + ' , '}
                name = name + '"№"';
            }
            return 'Необходимо заполнить обязательные поля: ' + name;
        }
    },
    async mounted() {
        this.loading = true;
        this.dateFromForInput = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}` + '-01');
        this.dateToForInput = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${this.getLastDayOfMonth(new Date().getMonth() + 1, new Date().getDate())}`)
        await this.getAccess();
        await this.loadStatusList();
        await this.loadAbpList();
        this.typeListLoad();
        this.currRegion = this.regionBase[0];
        this.selectedAlteration = this.listAlteration[0];
        if (this.accessLevels && this.currRegion !== undefined) {
            await this.loadData(this.year, this.currRegion, this.currAbp);
        }
        this.loading = false;
    },
    created() {
        this.$watch('reLoadChildABP', () => {
            if (this.reLoadChildABP) {
                this.loadData(this.year, this.currRegion, this.currAbp);
                this.$emit('unReLoadChildABP', false);
            }});

    },
    watch: {
        // selectedAlteration: function () {
        //     if (this.requestsData !== null) {
        //         if (this.accessLevels.position === "abp") {
        //             if (this.typeForm === 0) {
        //                 this.requestsBase = this.requestsData.filter((item) => item.requestType === 'individual');
        //           } else {
        //                 this.requestsBase = this.requestsData.filter((item) => item.requestType !== 'individual');
        //             }
        //         }
        //         if (this.accessLevels.position === "uf") {
        //             this.requestsBase = this.requestsData.filter((item) => item.requestType !== 'individual'); //(![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) &&
        //         }
        //         if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
        //             this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
        //         }
        //         if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
        //             this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
        //         }
        //     }
        //  },
        // selectedStatus: function () {
        //     if (this.requestsData !== null) {
        //         if (this.accessLevels.position === "abp") {
        //             if (this.typeForm === 0) {
        //                 this.requestsBase = this.requestsData.filter((item) => item.requestType === 'individual'); //(![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) &&
        //             } else {
        //                 this.requestsBase = this.requestsData.filter((item) => item.requestType !== 'individual'); //(![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) &&
        //             }
        //         }
        //         if (this.accessLevels.position === "uf") {
        //             this.requestsBase = this.requestsData.filter((item) => (![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) && item.requestType !== 'individual');
        //         }
        //         if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
        //             this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
        //         }
        //         if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
        //             this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
        //         }
        //     }
        // },
        selectedStatus: function () {
            localStorage[this.lsStatusFilter] = this.selectedStatus.code;
        },
        level: async function () {
            await this.loadAbpList();
            this.typeListLoad();
            await this.loadStatusList();
            this.currRegion = this.regionBase[0];
            this.selectedAlteration = this.listAlteration[0];
            if (this.accessLevels && this.currRegion !== undefined) {
                await this.loadData(this.year, this.currRegion, this.currAbp);
            }
        },
        typeForm: function () {
            if (this.typeForm === 0) {this.mode = 'exv'}
            else {
                if (this.typeForm === 1) {this.mode = 'exz'}
            }

        }
    },
    props: ["requestTypeOptions", "userId", "moduleCode", "regionBase", "yearList", "typeForm", "activeTab", "tabIndex", "reLoadChildABP"],
    methods: {
        getLastDayOfMonth(year, month) {
            const date = new Date(year, month + 1, 0);
            return date.getDate();
        },
        changeLevel() {
            if (this.level === 0) {
                this.accessLevels.position = 'abp';
            } else if (this.level === 1) {
                this.accessLevels.position = 'uf';
            }
        },
        async getAccess() {
            try {
                const result = await fetch(`/api-py/get-access-info/${this.moduleCode}/${this.userId}`);
                if (result.status === 200) {
                    const res = await result.json();
                    if (res.id > 0) {
                        this.loading = true;
                        res.editAccess = res['access_level'] !== 1;
                        this.accessLevels = res;
                        // if (this.typeForm === 1) {
                        let abp = 0;
                        let uo = 0;
                        this.accessLevels.operations.forEach((op, ind) => {
                            if (op.toLowerCase().lastIndexOf('abp') !== -1) {
                                abp += 1;
                            }
                            if (op.toLowerCase().lastIndexOf('uf') !== -1) {
                                uo += 1;
                            }
                        });
                        if (abp > 0) {
                            this.levels.push({ value: 0, text: 'АБП' });
                            this.accessLevels.position = 'abp'
                            this.level = 0;
                            if (uo > 0) { this.levels.push({ value: 1, text: 'УО' });}
                        } else if (uo > 0) { this.level = 1;}

                        this.loading = false;
                    }
                } else {
                    this.makeToast('danger', 'get-access', `${result.status} - ${result.statusText}`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-access', error.toString());
            }
        },
        onBack(val) {
            this.newForm = val;
            this.loadData(this.year, this.currRegion, this.currAbp);
            if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
                this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
            }
            if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
                this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
            }
        },
        onChange(val) {
            this.currentAlteration = val;
            this.modalConsolOpen(this.currentAlteration);
        },
        chgData(data) {
            try {
                this.requestsBase = data;
            } catch (error) {
                this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
            }
        }, // обновление параметров
        onChecked(val) {
            this.cDis = !(val > 0);
        },
        onOpen(val) {
            this.currentAlteration = val;
            this.newForm = true;
        },
        onDelete(val) {
            if (val) {
                this.deleteRequest(val);
            }
        },
        onClose() {
            this.modalForm = false;
            if (this.currentAlteration.hasOwnProperty('oldDate')) {
                this.$set(this.currentAlteration, 'date', this.currentAlteration.oldDate);
            }
        },
        async modalConsolOpen(item) {
            if (item === null) {
                const res = await fetch(
                    "/api/budget-execution-alteration-uf-request/create-request-template?region=" + this.currRegion.code + "&planType=EXPENSE");
                const data = await res.json();

                this.currAltStatusEdit = false;
                this.currentAlteration = data;
                this.requestStatus = false;
            } else {
                this.currAltStatusEdit = true;
                const arr = {
                    id: item.id,
                    number: item.number,
                    gu: item.gu,
                    abp: item.abp,
                    date: item.date,
                    requestType: item.requestType,
                    description: item.description,
                    user_id: item.user_id,
                    level: item.level,
                    fileList: item.fileList
                };
                this.currentAlteration = arr;
                this.requestStatus = true;
            }
            this.saveFalse = false;
            this.currentAlteration.date = new Date(this.currentAlteration.date);
            this.curDate = new Date(this.currentAlteration.date);
            this.dateFrom = new Date(`${this.curDate.getFullYear()}-${this.curDate.getMonth() + 1}` + '-01');
            this.dateTo = new Date(`${this.curDate.getFullYear()}-${this.curDate.getMonth() + 1}-${new Date(this.curDate.getFullYear(), this.curDate.getMonth() + 1, 0).getDate()}`)
            if (this.currentAlteration.region === null) {
                this.currentAlteration.region = this.currRegion.code;
            }
            if (this.currentAlteration.user_id === null) {
                this.currentAlteration.user_id = this.userId;
            }
            this.selectedReqType = null;
            for (const item of this.requestTypeOptions) {
                if (item.code === this.currentAlteration.requestType) {
                    this.selectedReqType = item;
                }
            }
            this.createdAlterationFiles = [];
            for (const file of this.currentAlteration.fileList) {
                this.createdAlterationFiles.push({...file});
            }
            for (let i = 0; i < this.createdAlterationFiles.length; i++) {
                this.createdAlterationFiles[i].old = true;
            }
            this.currentAlteration.fileList = null;
            this.modalForm = true
        },
        reqTypeClick() {
            this.currentAlteration.requestType = this.selectedReqType.code;
        },
        async loadStatusList() {
            let response = [];
            try {
                response = await fetch('/api-py/get-full-status-list/' + this.mode);
                this.listStatus = await response.json();
                if (localStorage[this.lsStatusFilter] !== undefined) {
                    this.listStatus.forEach((item) => {
                        if (item.code === parseInt(localStorage[this.lsStatusFilter])) {
                            this.selectedStatus = item;
                        }
                    });
                } else {
                    this.selectedStatus = this.listStatus[0];
                }
            } catch (error) {
                response = [];
                this.makeToast("danger", "Ошибка загрузки статусов", error.toString());
            }
        },
        async loadAbpList() {
            let response = [];
            this.abpBase = [];
            response = await fetch('/api-py/user-abp-list/' + this.userId);
            const newBase = await response.json();
            this.abpBase = this.setIdArr(newBase, "abp");
            if (['uf'].includes(this.accessLevels.position)) {
                this.abpBase.push({
                    "name_ru": "Все",
                    "name_kk": "Барлығы",
                    "abp": 0
                });
            }
            if (this.abpBase.length > 0) {
                this.abpBase
                    .sort((a, b) => (a.abp - b.abp > 0 ? 1 : -1))
                    .map((item) => {
                        if (item.abp == 0) {item.localText = `${item["name_" + this.$i18n.locale]}`}
                        else
                        {item.localText = `${item.abp} - ${item["name_" + this.$i18n.locale]}`}
                        return item;
                    });
                this.currAbp = this.abpBase[0];
            }
        },
        setIdArr(arr, codeName) {
            const result = [];
            for (const el of arr) {
                if (
                    result.length > 0 &&
                    el[codeName] === result[result.length - 1][codeName]
                ) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({ idArr: [el.id] }, el));
                }
            }
            return result;
        },
        roleIncludes(val){
            let ed = 0;
            if (this.accessLevels && this.accessLevels.operations) {
                this.accessLevels.operations.forEach((op, ind) => {
                    if (op.toLowerCase().lastIndexOf(val) !== -1) {
                        ed += 1;
                    }
                });
            }
            return ed > 0
        },
        async loadData(year, region, abp) {
            this.loading = true;
            let response = [];
            const dateFrom = this.dateFromForInput.toLocaleDateString().split('.').reverse().join('-');
            const dateTo = this.dateToForInput.toLocaleDateString().split('.').reverse().join('-');
            if ((year && region !== null && abp)) {
                try {
                    if (abp !== null && abp.abp !== 0) {
                        response = await fetch(
                            `/api/budget-execution-alteration-abp-request/list?region=${region.code}&dateBegin=${dateFrom}&dateEnd=${dateTo}&abp=${abp.abp}`
                        );
                    } else {
                        response = await fetch(
                            `/api/budget-execution-alteration-abp-request/list?region=${region.code}&dateBegin=${dateFrom}&dateEnd=${dateTo}`
                        );
                    }
                    // if (this.roleIncludes('abp_sign')) {
                    //     response = await fetch(
                    //         `/api/budget-execution-alteration-abp-request/list?dateBegin=${dateFrom}&dateEnd=${dateTo}&abp=${abp.abp}`
                    //     );
                    // }
                    response = await response.json();
                    this.getData(response);
                    this.loading = false;
                } catch (error) {
                    response = [];
                    this.loading = false;
                    this.makeToast(
                        "danger",
                        "Ошибка загрузки заключений4",
                        error.toString()
                    );
                }
            }
        },
        getData(data) {
            this.loading = true;
            this.requestsBase = [];
            this.requestsData = [];
            this.requestsData = data.sort((a, b) => b.create_date - a.create_date);
            this.requestsData.forEach((item, index) => {
                this.abpBase.forEach((row, ind) => {
                    if (row.abp === item.abp) {
                        this.$set(item, 'localText', row.localText);
                        this.$set(item, 'check', false);
                        if (item.status === null) {
                            this.$set(item, 'status', {status: item.statusList[item.statusList.length-1].status});
                        }
                    }
                });
                if ((['uf'].includes(this.accessLevels.position) && item.level === 'abp' && (item.status !== null && [32].includes(item.status.status)) && item.parent === null)) {
                    this.$set(item, 'checking', true);
                }
                else {
                    this.$set(item, 'checking', false);
                }
            });
            this.addUserName(this.requestsData);
            this.requestsBase = this.requestsData;
            if (this.selectedAlteration !== undefined && this.selectedAlteration.code !== 'all') {
                this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
            }
            if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
                this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
            }
            if (this.typeForm > 0) {
                this.requestsBase = this.requestsBase.filter((item) => item.requestType !== 'individual');
                 if (this.accessLevels.position === "uf") {
                     this.requestsBase = this.requestsBase.filter((item) => ![1].includes(item.status.status));
                 }
            } else {
                this.requestsBase = this.requestsBase.filter((item) => item.requestType === 'individual');
            }
            this.loading = false;
        },
        addUserName(data) {
            const userIdList = [];
            data.forEach((item) => {userIdList.push(item.user_id);});
            const unicUserIdList = [...new Set(userIdList)];
            this.loadUsers(unicUserIdList);
        },
        async loadUsers(arr) {
            try {
                for (const item of arr) {
                    const response = await fetch('/api-py/get-realm-user/' + item);
                    const currUser = await response.json();
                    if (currUser !== undefined){
                        this.userList.push(currUser);
                    }
                }
                this.getUsers();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки пользователей', error.toString());
            }
        },
        getUsers() {
            if (!this.requestsBase) return;
            this.requestsBase.forEach((item) => {
                this.userList.map((user) => {
                    if ((user !== null) && (user.id === item.user_id)) {
                        const userName = `${user.firstName} ${user.lastName}`;
                        this.$set(item, "userName", userName);
                    }
                });
            });
        },
        clearParam() {
            this.currRegion = null;
            this.currAbp = null;
            this.year = `${new Date().getFullYear()}`;

        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'filterYear':
                    refItem = this.$refs.filterYear;
                    break;
                case 'filterAbp':
                    refItem = this.$refs.filterAbp;
                    break;
                case 'filterReg':
                    refItem = this.$refs.filterReg;
                    break;
                case 'refAlteration':
                    refItem = this.$refs.refAlteration;
                    break;
                case 'refStatus':
                    refItem = this.$refs.refStatus;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        handleCloseFirstModal() {
            this.closingFirstModal = true;
        },
        handleCloseSecondModal() {
            this.closingFirstModal = false;
        },
        reset: function() {
            this.deletedAlterationFiles = [];
            this.loadData(this.year, this.currRegion, this.currAbp);
        },
        async saveRequest() {
            this.saveTrue = false;
            this.cDis = true;
            if (this.currentAlteration.description !== null && this.currentAlteration.requestType !== null && this.currentAlteration.number !== '') {
                this.loading = true;
                await this.svRequest();
            } else {
                this.saveTrue = true;
            }
        },
        syncFile: function (e) {
            e.preventDefault();
            this.createdAlterationFiles.push(e.target.files[0]);
            const files = document.getElementById('fileInput').value;
        },
        async svRequest() {
            this.currentAlteration.date = this.currentAlteration.date.getTime();
            const res = await fetch(this.accessLevels.position === 'abp'? "/api/budget-execution-alteration-abp-request" : "/api/budget-execution-alteration-uf-request",
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: this.currentAlteration.id ? "PUT" : "POST",
                    body: JSON.stringify(this.currentAlteration)
                })
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(async json => {
                            this.currentAlteration = json;
                            if (!this.currentAlteration.status) {
                                this.$set(this.currentAlteration, "status", []);
                                if (!this.currentAlteration.status.status) {
                                    this.$set(this.currentAlteration.status, "status", 1);
                                }
                            }
                            if (this.accessLevels.position === 'abp' && !this.requestStatus) {
                                this.$emit('referencesABPActive', this.currentAlteration);
                            } else {
                                this.$emit('applicationsABPActive', this.currentAlteration);
                            }
                            for (let i = 0; i < this.createdAlterationFiles.length; i++) {
                                if (!this.createdAlterationFiles[i].old) {
                                    await this.saveFile(json.id, this.createdAlterationFiles[i])
                                }
                            }
                            for (let i = 0; i < this.deletedAlterationFiles.length; i++) {
                                await this.deleteFile(this.deletedAlterationFiles[i].id, this.deletedAlterationFiles[i])
                            }
                            // Добавить заявку Гу в заявку Абп
                            for (let i = 0; i < this.requestsBase.length; i++) {
                                if (this.requestsBase[i].check) {
                                    await this.saveGuRequest(json.id, this.requestsBase[i].id);
                                    this.requestsBase[i].check = false;
                                }
                            }
                            this.reset();
                            this.loading = false;
                            if (this.accessLevels.position !== 'abp') {
                                this.newForm = false;
                                this.saveFalse = false;
                            }
                            // return !['uf'].includes(this.accessLevels.position);
                        });
                        this.modalForm = false;
                    } else {
                        this.loading = false;
                    }
                    if (response.status === 500) {
                        response.json().then(async json => {
                            if (json.key === 'modules.budgetExecutionAlteration.error.requestNumberAlreadyExists') {
                                this.saveFalse = true;
                                this.loading = false;
                            }
                            this.currentAlteration.date = new Date(this.currentAlteration.date);
                        })
                    }
                })
        },
        deleteRequest(item) {
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление данной записи...',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.deleteReq(item);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },
        async deleteReq(item){
            const result = await fetch("/api/budget-execution-alteration-abp-request?requestId=" + item.id, {
                method: "DELETE",
            });
            if (result.status === 200) {
                const ind1 = this.requestsData.indexOf(item);
                this.requestsData.splice(ind1, 1);

                const ind2 = this.requestsBase.indexOf(item);
                this.requestsBase.splice(ind2, 1);
            } else {
                const res = await result.json();
                if (res.key === 'modules.budgetExecutionAlteration.error.requestHistoryAbpCheck') {
                    this.makeToast('danger', 'Сообщение', 'Согласованная уполномоченным органом заявка не может быть удалена');
                } else {
                    this.makeToast('danger', 'Сообщение', 'Ошибка удаления');
                }
            }
            setTimeout(() => this.$emit('reLoad', true), 1000);
        },
        saveFile: async function (id, file) {
            const data = new FormData();
            data.append("file", file);
            return await fetch("/api/budget-execution-alteration-request/file?requestId=" + id,
                {
                    method: "POST",
                    body: data
                })
                .then(response => {
                })
                .catch(error => {
                    alert("Error!");
                    console.log(error);
                });
        },
        onReLoad(val){ this.$emit('reLoad', val); },
        saveGuRequest: async function (requestId, guRequestId) {
            // const params = { guRequestId: guRequestId, requestId: requestId }
            return await fetch(`/api/budget-execution-alteration-uf-request/add-gu-request?guRequestId=${guRequestId}&requestId=${requestId}`,
                {
                    method: "POST"
                })
                .then(response => {
                })
                .catch(error => {
                    alert("Error!");
                    console.log(error);
                });
        },
        deleteFile: async function (id) {
            return await fetch("/api/budget-execution-alteration-request/file?fileId=" + id,
                {
                    method: "DELETE",
                })
        },
        altRefAbp(val) {
            this.currentAlteration = val;
            this.newForm = true;
        },
        altAllAbp(val) {
            this.currentAlteration = val;
            this.newForm = true;
        },
        typeListLoad() {
            this.requestTypeList.splice(0);
            this.listAlteration.splice(0);
            this.listAlteration.push({code: 'all', name_ru: 'Все'});
            if (['uf'].includes(this.accessLevels.position)) {
                if (this.requestTypeOptions && this.requestTypeOptions.length > 0) {
                    this.requestTypeOptions.forEach((item) => {
                        if (!['individual', 'budget_income', 'budget_income_clarification', 'budget_income_movement'].includes(item.code)) {
                            this.requestTypeList.push(item);
                            this.listAlteration.push(item);
                        }
                    });
                }
            }
            if (['abp'].includes(this.accessLevels.position)) {
                if (this.typeForm === 0) {
                    if (this.requestTypeOptions && this.requestTypeOptions.length > 0) {
                        this.requestTypeOptions.forEach((item) => {
                             if (['individual'].includes(item.code)) {
                                this.listAlteration.push(item);
                            }
                            if (!['budget_income', 'budget_income_clarification', 'budget_income_movement'].includes(item.code)) {
                                this.requestTypeList.push(item);
                            }
                        });
                    }
                } else {
                    if (this.requestTypeOptions && this.requestTypeOptions.length > 0) {
                        this.requestTypeOptions.forEach((item) => {
                            if (['movement', 'budget_clarification', 'budget_adjustment'].includes(item.code)) {
                                this.listAlteration.push(item);
                            }
                            if (!['budget_income', 'budget_income_clarification', 'budget_income_movement'].includes(item.code)) {
                                this.requestTypeList.push(item);
                            }
                        });
                    }
                }
            }
        },
        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            this.saveFalse = false
        }, // ввод по заданному паттерну,
        dateChange(){
            if (this.currAltStatusEdit){
                if (this.curDate >= this.dateFrom && this.curDate <= this.dateTo) {
                    this.$set(this.currentAlteration, 'oldDate', this.currentAlteration.date);
                    this.currentAlteration.date = this.curDate;
                } else {
                    this.curDate = this.currentAlteration.date;
                }
            } else { this.currentAlteration.date = this.curDate; }
        },
        filterApply(){
            if (this.requestsData !== null) {
                if (this.accessLevels.position === "abp") {
                    if (this.typeForm === 0) {
                        this.requestsBase = this.requestsData.filter((item) => item.requestType === 'individual'); //(![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) &&
                    } else {
                        this.requestsBase = this.requestsData.filter((item) => item.requestType !== 'individual'); //(![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) &&
                    }
                }
                if (this.accessLevels.position === "uf") {
                    this.requestsBase = this.requestsData.filter((item) => (![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) && item.requestType !== 'individual');
                }
                if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
                    this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
                }
                if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
                    this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
                }
            }
        },
        clearFilter(){
            this.selectedAlteration = this.listAlteration[0];
            this.selectedStatus = this.listStatus[0];
            if (this.accessLevels.position === "abp") {
                if (this.typeForm === 0) {
                    this.requestsBase = this.requestsData.filter((item) => item.requestType === 'individual'); //(![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) &&
                } else {
                    this.requestsBase = this.requestsData.filter((item) => item.requestType !== 'individual'); //(![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) &&
                }
            }
            if (this.accessLevels.position === "uf") {
                this.requestsBase = this.requestsData.filter((item) => (![1].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)) && item.requestType !== 'individual');
            }
        }
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    gap: 20px;
    background-color: #eef1f8;
    justify-content: space-between;
}

.content {
    height: 100%;
    .title {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 20px;
        margin-bottom: 26px;
    }
    .constructor,
    .forms {
        flex: 1;
    }

    .body {
        background-color: #f7f9fc;
    }

    .item {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .multiselect {
        max-width: 400px;
    }

    .footer {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: end;
        border: 1px solid #d3dbe3;
        border-top: 0;
        padding-right: 40.5%;
    }

    .vertical-line {
        width: 1px;
        background-color: #d3dbe3;
        margin-top: 40px;
    }
}
.add_modal {
    width:100%;
    height:100%;
    &__body {
        background-color: #f7f9fc;
        border: 1px solid #d3dbe3;
        border-radius: 5px;
        margin-bottom: 23px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .input-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
        &-label {
            width: 400px;
            font-weight: 600;
            font-size: 14px;
            color: #6087a0;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
        }
    }
    .form-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
    }
}
.button {
    border: none;
    border-radius: 10px;
}

.button-close {
    color: red;
}
.second-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 26px;
    &__title {
        font-weight: 500;
        font-size: 14px;
    }
}

.date-picker-container {
    display: flex;
    gap: 79px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6087a0;

    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
}

</style>

