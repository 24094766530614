






















































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

interface ISelected {
    katIndx: number;
    clssIndx: number;
    pclIndx: number;
    spfIndx: number;
    [propName: string]: any;
}

interface IMonthVal {
    month0: number | string |null;
    month1: number | string |null;
    month2: number | string |null;
    month3: number | string |null;
    month4: number | string |null;
    month5: number | string |null;
    month6: number | string |null;
    month7: number | string |null;
    month8: number | string |null;
    month9: number | string |null;
    month10: number | string |null;
    month11: number | string |null;
    [propName: string]: any;
}

interface IMonthTotal {
    month0: number;
    month1: number;
    month2: number;
    month3: number;
    month4: number;
    month5: number;
    month6: number;
    month7: number;
    month8: number;
    month9: number;
    month10: number;
    month11: number;
}

const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

@Component({
    name: 'inc-tree-table'
})
export default class IncTreeTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: any;

    private treeData: any[] = [];

    private collapseAll = true;

    private selectedRow: ISelected | null = null;

    private allSum: any = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 };

    private getTreeData() {
        if (!this.tableData) {
            this.treeData = [];
            return;
        }
        if (this.selectedRow) {
            if (this.selectedRow.katIndx >= this.treeData.length) {
                this.selectedRow = null;
            } else {
                if (this.selectedRow.clssIndx >= this.treeData[this.selectedRow.katIndx].child_list.length) {
                    this.selectedRow = null;
                } else {
                    if (this.selectedRow.pclIndx >= this.treeData[this.selectedRow.katIndx].child_list[this.selectedRow.clssIndx].child_list.length) {
                        this.selectedRow = null;
                    } else {
                        if (this.selectedRow.spfIndx >= this.treeData[this.selectedRow.katIndx].child_list[this.selectedRow.clssIndx].child_list[this.selectedRow.pclIndx].child_list.length) {
                            this.selectedRow = null;
                        }
                    }
                }
            }
        }

        const treeData: any[]= [];
        const totalSum: IMonthTotal = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 };
        const allSum: IMonthTotal = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 };
        const tableData = JSON.parse(JSON.stringify(this.tableData.slice()));
        for (let i = 0; i < tableData.length; i++) {
            const el = tableData[i];
            const objKat = Object.assign({}, el);
            if (objKat.child_list && objKat.child_list.length) {
                if (objKat.collapse === undefined) { objKat.collapse = this.collapseAll; }
                for (let j = 0; j< objKat.child_list.length; j++) {
                    objKat.child_list.sort((a: any, b: any) => (a.cls > b.cls) ? 1 : -1);
                    const elClss = objKat.child_list[j];
                    if (elClss.child_list && elClss.child_list.length) {
                        if (elClss.collapse === undefined) { elClss.collapse = this.collapseAll; }
                        for (let p = 0; p < elClss.child_list.length; p++) {
                            const elPcl = elClss.child_list[p];
                            elClss.child_list.sort((a: any, b: any) => (a.pcl > b.pcl) ? 1 : -1);
                            if (elPcl.collapse === undefined) { elPcl.collapse = this.collapseAll; }
                            if (elPcl.child_list && elPcl.child_list.length) {
                                for (let s = 0; s < elPcl.child_list.length; s++) {
                                    const elSpf = elPcl.child_list[s];
                                    elPcl.child_list.sort((a: any, b: any) => (a.spf > b.spf) ? 1 : -1);
                                    elSpf._katIndx = i;
                                    elSpf._clssIndx = j;
                                    elSpf._pclIndx = p;
                                    elSpf._spfIndx = s;
                                    elSpf.selectedRow = null;
                                }
                            }
                        }
                    }
                }
            }
            treeData.push(objKat);
            this.getSumTotal(allSum, objKat);
            treeData.sort((a: any, b: any) => (a.kat > b.kat) ? 1 : -1);
        }
        this.treeData = treeData;
        this.allSum = allSum;
        if (this.selectedRow) { this.selectRow(this.selectedRow.katIndx, this.selectedRow.clssIndx, this.selectedRow.pclIndx, this.selectedRow.spfIndx); }
    }

    private getSumTotal(total: any, obj: IMonthVal) {
        if (obj.month0) { total.month0 = Math.round(((typeof obj.month0 == 'number' ? obj.month0 : parseFloat(obj.month0)) + total.month0)*10)/10; }
        if (obj.month1) { total.month1 = Math.round(((typeof obj.month1 == 'number' ? obj.month1 : parseFloat(obj.month1)) + total.month1)*10)/10; }
        if (obj.month2) { total.month2 = Math.round(((typeof obj.month2 == 'number' ? obj.month2 : parseFloat(obj.month2)) + total.month2)*10)/10; }
        if (obj.month3) { total.month3 = Math.round(((typeof obj.month3 == 'number' ? obj.month3 : parseFloat(obj.month3)) + total.month3)*10)/10; }
        if (obj.month4) { total.month4 = Math.round(((typeof obj.month4 == 'number' ? obj.month4 : parseFloat(obj.month4)) + total.month4)*10)/10; }
        if (obj.month5) { total.month5 = Math.round(((typeof obj.month5 == 'number' ? obj.month5 : parseFloat(obj.month5)) + total.month5)*10)/10; }
        if (obj.month6) { total.month6 = Math.round(((typeof obj.month6 == 'number' ? obj.month6 : parseFloat(obj.month6)) + total.month6)*10)/10; }
        if (obj.month7) { total.month7 = Math.round(((typeof obj.month7 == 'number' ? obj.month7 : parseFloat(obj.month7)) + total.month7)*10)/10; }
        if (obj.month8) { total.month8 = Math.round(((typeof obj.month8 == 'number' ? obj.month8 : parseFloat(obj.month8)) + total.month8)*10)/10; }
        if (obj.month9) { total.month9 = Math.round(((typeof obj.month9 == 'number' ? obj.month9 : parseFloat(obj.month9)) + total.month9)*10)/10; }
        if (obj.month10) { total.month10 = Math.round(((typeof obj.month10 == 'number' ? obj.month10 : parseFloat(obj.month10)) + total.month10)*10)/10; }
        if (obj.month11) { total.month11 = Math.round(((typeof obj.month11 == 'number' ? obj.month11 : parseFloat(obj.month11)) + total.month11)*10)/10; }
    }

    private clkCollapse(katIndx: number | null, clssIndx: number | null, pclIndx: number | null, collapse?: boolean) {
        if (katIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.treeData) {
                el.collapse = this.collapseAll;
                for (const clssEl of el.child_list) {
                    clssEl.collapse = this.collapseAll;
                    for (const pclEl of clssEl.child_list) {
                        if (pclEl.collapse!==undefined) { pclEl.collapse = this.collapseAll; }
                    }
                }
            }
            return;
        }

        if (collapse === undefined) { return; }

        if (clssIndx === null) {
            this.treeData[katIndx].collapse = collapse;
            if (!collapse && this.collapseAll) { this.collapseAll = false; }
            if (collapse !== this.collapseAll) {
                let fl = false;
                for (let i =0; i < this.treeData.length; i++) {
                    if (this.treeData[i].collapse !== collapse) {
                        fl = true;
                        break;
                    }
                }
                if (!fl) { this.collapseAll = collapse; }
            }
            for (const clssEl of this.treeData[katIndx].child_list) {
                clssEl.collapse = collapse;
                for (const pclEl of clssEl.child_list) {
                    if (pclEl.collapse!==undefined) { pclEl.collapse = collapse; }
                }
            }
        } else {
            if (pclIndx === null) {
                this.treeData[katIndx].child_list[clssIndx].collapse = collapse;
                for (const pclEl of this.treeData[katIndx].child_list[clssIndx].child_list) {
                    if (pclEl.collapse!==undefined) { pclEl.collapse = collapse; }
                }
            } else {
                if (this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].collapse!==undefined) { this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].collapse = collapse; }
            }
        }
        this.treeData.push({});
        this.treeData.splice(this.treeData.length-1, 1);
    }

    private mounted() {
        this.getTreeData();
        this.$watch('tableData', () => { this.getTreeData(); }, { deep: true });
    }

    private selectRow(katIndx: number, clssIndx: number, pclIndx: number, spfIndx: number) {
        if (this.selectedRow) {
            const obj = this.treeData[this.selectedRow.katIndx].child_list[this.selectedRow.clssIndx].child_list[this.selectedRow.pclIndx].child_list[this.selectedRow.spfIndx];
            obj.selectedRow = null;
        }
        const obj = this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].child_list[spfIndx];
        obj.selectedRow = true;
        const selectedRow = Object.assign({ katIndx: katIndx, clssIndx: clssIndx, pclIndx: pclIndx, spfIndx: spfIndx }, obj);
        let sum = 0;
        for (const monthName of monthLst) {
            sum += this.sumMonth(selectedRow, monthName);
        }
        selectedRow.sumMonth = Math.round(sum * 10) / 10;

        this.calcSumSelectedAndSpf(selectedRow);
        this.selectedRow = selectedRow;
    }

    private calcSumSelectedAndSpf(selectedRow: any) { // расчёт суммы планового значения и специфики (например january и month0)
        const sumMonthTmp = selectedRow.sumMonth;
        selectedRow.sumMonthTmp = Math.round(sumMonthTmp * 10)/10;
    }

    private sumMonth(monthObj: any, fieldName: string) {
        if (!monthObj[fieldName]) { monthObj[fieldName] = 0; }
        return monthObj[fieldName];
    }
}
