


























































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITreeData, IPpr, IMonthVal, IMonthTotal, ISelected } from "./treeTableData";
import CPopUp from "../CPopUp.vue";

const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

@Component({
    name: 'c-gu-tree-table',
    components: { 'c-pop-up': CPopUp }
})
export default class CGuTreeTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: []
    })
    private editedMonths!: number[]; // 0-11

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: any;

    @Prop({
        required: true,
        default: null
    })
    private uSumVisible!: number;
    @Prop({
        required: true,
        default: ''
    })
    private flc2!: any;

    @Prop({
        required: true,
        default: ''
    })
    private flc4!: any;

    private collapseAll = true;

    private selectedRow: ISelected | null = null;

    private treeData: ITreeData[] = [];

    private totalSum: IMonthTotal = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0, utochSum: 0, sum: 0, ost: 0, ostDate: '' };

    private mounted() {
        this.getTreeData();
        this.$watch('tableData', () => {
            this.getTreeData();
        }, { deep: true });
    }

    private get editedArr() {
        const editedArr = [false, false, false, false, false, false, false, false, false, false, false, false];
        if (!this.accessLevel.editAccess) { return editedArr; }
        for (const el of this.editedMonths) {
            editedArr[el] = true;
        }
        return editedArr;
    }

    private makeTreeArr(arr: any[], fieldName: string) {
        const result: any[] = [];
        const totalSum: IMonthTotal = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0, utochSum: 0, ost: 0, ostDate: '' };
        for (const el of arr) {
            if (!result.length || result[result.length-1][fieldName] !== el[fieldName]) {
                const obj: any = { vals: [el], collapse: this.collapseAll, totalSum: Object.assign({}, totalSum), utochSum: 0 };
                this.getSumTotal(obj.totalSum, el);
                obj[fieldName] = el[fieldName];
                obj[`${fieldName}Name`] = el[`${fieldName}_name`];
                result.push(obj);
            } else {
                this.getSumTotal(result[result.length-1].totalSum, el);
                result[result.length-1].vals.push(el);
            }
        }
        return result;
    }
    private getFlk2(el: any) { // проверка флк
        for (let i=0; i<12; i++) {
            let rowCh = false;
            if (el[`month${i}`] < 0) {
                if (Math.abs(el[`month${i}`]) > el[`ost`]) {
                    if (!el.flk) {
                        el.flk = {};
                    }
                    if (!el.flk[`month${i}`]) {
                        el.flk[`month${i}`] = [];
                    }
                    let sum = 0;
                    for (let j = 0; j < i + 1; j++) {
                        sum = sum + el[`month${j}`]
                    }
                    const sums = parseFloat(String(Math.abs(sum) - el[`ost`]));
                    // console.log('sums', sums);
                    if (sums !== 0) {
                        el.flk[`month${i}`].forEach((row: any) => {
                            if (row.hasOwnProperty('id')) {
                                if (row.id === 2) {
                                    rowCh = true;
                                }
                            }
                        });
                        if (!rowCh) {
                            el.flk[`month${i}`].push({
                                id: 2,
                                typeErr: this.flc2,
                                textErr: 'Внимание! Введенная сумма превышает остаток по плану (КФ 4-20) на  ' + ((sums).toFixed(2)).toString() + ' тыс.тг'
                            });
                        }
                        this.$emit('agrBtn', this.flc2.length > 0);
                        this.$emit('flcControls', {
                            id: 2,
                            typeErr: this.flc2,
                            textErr: 'Введенная сумма превышает остаток по плану (КФ 4-20)'
                        });
                    }
                }
            }
        }
    }
    private getFlk4(el: any) { // проверка флк
        for (let i=0; i<12; i++) {
            let rowCh = false;
            if (el[`month${i}`] + el[monthLst[i]] < 0) {
                if (!el.flk) { el.flk = {}; }
                if (!el.flk[`month${i}`]) { el.flk[`month${i}`] = []; }
                el.flk[`month${i}`].forEach((row: any) => {
                    if (row.hasOwnProperty('id')) {
                        if (row.id === 4) {
                            rowCh = true;
                        }
                    }
                });
                if (!rowCh) {
                    el.flk[`month${i}`].push({
                        id: 4,
                        typeErr: 'error',
                        textErr: 'Внимание! Введенная сумма превышает остаток по плану'
                    });
                }
                this.$emit('agrBtn', this.flc4.length > 0);
                this.$emit('flcControls', { id: 4, typeErr: this.flc4, textErr: 'Введенная сумма превышает остаток по плану' });
            }
        }
    }
    private getTreeData() {
        const treeData: ITreeData[] = [];
        this.totalSum = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0, utochSum: 0, sum: 0, ost: 0, ostDate: '' };
        if (!this.tableData) {
            this.treeData = [];
            return;
        }

        const guArr: any[] = [];
        const tableData = this.tableData.slice();
        for (let i = 0; i < tableData.length; i++) {
            const el  = tableData[i];
            if (!el.ost) {
                el.ost = 0;
            } else {
                el.ost = Math.round(el.ost*10)/10;
            }
            for (let j = 0; j < 12; j++) {
                if (!el.flk) {
                    el.flk = {};
                }
                if (!el.flk[`month${j}`]) {
                    el.flk[`month${j}`] = [];
                }
            }
            this.getFlk4(el);
            this.getFlk2(el);
            if (el.child_list && el.child_list.length) { el.collapse = this.collapseAll; }
            el._indxArr = i;
        }
        tableData.sort((a: any, b: any) => (a.gu > b.gu) ? 1 : -1);
        for (const el of tableData) {
            if (!guArr.length || guArr[guArr.length-1].gu !== el.gu) {
                guArr.push({ gu: el.gu, guName: el.gu_name,  vals: [el], collapse: this.collapseAll, totalSum: { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0, utochSum: 0, ost: 0, ostDate: '' } });
            } else {
                guArr[guArr.length-1].vals.push(el);
            }
        }

        for (const elGu of guArr) {
            elGu.vals.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
            const resPrg = this.makeTreeArr(elGu.vals, 'prg');
            for (const elPrg of resPrg) {
                elPrg.vals.sort((a: any, b: any) => (a.ppr > b.ppr) ? 1 : -1);
                const resPpr = this.makeTreeArr(elPrg.vals, 'ppr');
                for (const pprEl of resPpr) {
                    pprEl.vals.sort((a: any, b: any) => (a.spf > b.spf) ? 1 : -1);
                }
                elPrg.vals = resPpr;
                this.getSumTotal(elGu.totalSum, elPrg.totalSum);
            }
            elGu.vals = resPrg;
            this.getSumTotal(this.totalSum, elGu.totalSum);
        }
        this.totalSum.sum = this.getSum(this.totalSum);
        this.treeData = guArr;
    }

    private getSum(obj: any) {
        let sum = 0;
        for (let i=0; i < 12; i++) {
            if (obj[`month${i}`]) {
                sum = Math.round(((typeof obj[`month${i}`] == 'number' ? obj[`month${i}`] : parseFloat(obj[`month${i}`])) + sum)*10)/10;
            }
        }
        return sum;
    }

    private getSumTotal(total: any, obj: IMonthVal) {
        if (obj.month0) { total.month0 = Math.round(((typeof obj.month0 == 'number' ? obj.month0 : parseFloat(obj.month0)) + total.month0)*10)/10; }
        if (obj.month1) { total.month1 = Math.round(((typeof obj.month1 == 'number' ? obj.month1 : parseFloat(obj.month1)) + total.month1)*10)/10; }
        if (obj.month2) { total.month2 = Math.round(((typeof obj.month2 == 'number' ? obj.month2 : parseFloat(obj.month2)) + total.month2)*10)/10; }
        if (obj.month3) { total.month3 = Math.round(((typeof obj.month3 == 'number' ? obj.month3 : parseFloat(obj.month3)) + total.month3)*10)/10; }
        if (obj.month4) { total.month4 = Math.round(((typeof obj.month4 == 'number' ? obj.month4 : parseFloat(obj.month4)) + total.month4)*10)/10; }
        if (obj.month5) { total.month5 = Math.round(((typeof obj.month5 == 'number' ? obj.month5 : parseFloat(obj.month5)) + total.month5)*10)/10; }
        if (obj.month6) { total.month6 = Math.round(((typeof obj.month6 == 'number' ? obj.month6 : parseFloat(obj.month6)) + total.month6)*10)/10; }
        if (obj.month7) { total.month7 = Math.round(((typeof obj.month7 == 'number' ? obj.month7 : parseFloat(obj.month7)) + total.month7)*10)/10; }
        if (obj.month8) { total.month8 = Math.round(((typeof obj.month8 == 'number' ? obj.month8 : parseFloat(obj.month8)) + total.month8)*10)/10; }
        if (obj.month9) { total.month9 = Math.round(((typeof obj.month9 == 'number' ? obj.month9 : parseFloat(obj.month9)) + total.month9)*10)/10; }
        if (obj.month10) { total.month10 = Math.round(((typeof obj.month10 == 'number' ? obj.month10 : parseFloat(obj.month10)) + total.month10)*10)/10; }
        if (obj.month11) { total.month11 = Math.round(((typeof obj.month11 == 'number' ? obj.month11 : parseFloat(obj.month11)) + total.month11)*10)/10; }
        if (obj.utochSum) { total.utochSum = Math.round(((typeof obj.utochSum == 'number' ? obj.utochSum : parseFloat(obj.utochSum)) + total.utochSum)*10)/10; }
        if (obj.ost) { total.ost = Math.round(((typeof obj.ost == 'number' ? obj.ost : parseFloat(obj.ost)) + total.ost)*10)/10; }
        if (obj.ostDate) { total.ostDate = obj.ostDate; }
    }

    private clkCollapse(guIndx: number | null, prgIndx: number | null, subIndx: number | null, spfIndx: number | null, collapse?: boolean) {
        if (guIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const guEl of this.treeData) {
                guEl.collapse = this.collapseAll;
                for (const el of guEl.vals) {
                    el.collapse = this.collapseAll;
                    for (const subEl of el.vals) {
                        subEl.collapse = this.collapseAll;
                        for (const spfEl of subEl.vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = this.collapseAll; }
                        }
                    }
                }
            }
            return;
        }

        if (collapse ===undefined) { return; }

        if (prgIndx === null) {
            this.treeData[guIndx].collapse = collapse;
            if (!collapse && this.collapseAll) { this.collapseAll = false; }
            if (collapse !== this.collapseAll) {
                let fl = false;
                for (let i = 0; i < this.treeData.length; i++) {
                    if (this.treeData[i].collapse !== collapse) {
                        fl = true;
                        break;
                    }
                }
                if (!fl) { this.collapseAll = collapse; }
            }
            for (const prgEl of this.treeData[guIndx].vals) {
                prgEl.collapse = collapse;
                for (const subEl of prgEl.vals) {
                    subEl.collapse = collapse;
                    for (const spfEl of subEl.vals) {
                        if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                    }
                }
            }
        } else {
            if (subIndx === null) {
                this.treeData[guIndx].vals[prgIndx].collapse = collapse;
                for (const subEl of this.treeData[guIndx].vals[prgIndx].vals) {
                    subEl.collapse = collapse;
                    for (const spfEl of subEl.vals) {
                        if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                    }
                }
            } else {
                console.log(subIndx);
                if (spfIndx === null) {
                    this.treeData[guIndx].vals[prgIndx].vals[subIndx].collapse = collapse;
                    for (const spfEl of this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals) {
                        if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                    }
                } else {
                    if (this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].collapse!==undefined) { this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].collapse = collapse; }
                }
            }
        }
    }

    private selectRow(guIndx: number, prgIndx: number, subIndx: number, spfIndx: number, formIndx: number | null){
        if (this.selectedRow) {
            const obj = this.getSelectedObj(guIndx, this.selectedRow.prgIndx, this.selectedRow.subIndx, this.selectedRow.spfIndx, this.selectedRow.formIndx);
            obj.selectedRow = null;
            /* if (this.selectedRow.prgIndx === prgIndx && this.selectedRow.subIndx === subIndx && this.selectedRow.spfIndx === spfIndx && this.selectedRow.formIndx === formIndx) {
                this.selectedRow = null;
                obj.selectedRow = null;
                return;
            } */
        }
        const obj = this.getSelectedObj(guIndx, prgIndx, subIndx, spfIndx, formIndx);
        obj.selectedRow = true;
        const selectedRow = Object.assign({ guIndx: guIndx, prgIndx: prgIndx, subIndx: subIndx, spfIndx: spfIndx, formIndx: formIndx }, obj);
        let sum = 0;
        for (const monthName of monthLst) {
            sum += this.sumMonth(selectedRow, monthName);
        }
        selectedRow.sumMonth = Math.round(sum * 10) / 10;

        this.calcSumSelectedAndSpf(selectedRow);

        this.selectedRow = selectedRow;
    }

   private calcSumSelectedAndSpf(selectedRow: any) { // расчёт суммы планового значения и специфики (например january и month0)
        let sumMonthTmp = selectedRow.sumMonth;
        for (let i=0; i < 12; i++) {
            const obj = this.getSelectedObj(selectedRow.guIndx, selectedRow.prgIndx, selectedRow.subIndx, selectedRow.spfIndx, selectedRow.formIndx);
            selectedRow[`${monthLst[i]}_tmp`] = Math.round((selectedRow[monthLst[i]] + obj[`month${i}`]) * 10) / 10;
            sumMonthTmp += obj[`month${i}`];
        }
        selectedRow.sumMonthTmp = Math.round(sumMonthTmp * 10)/10;
    }

    private sumMonth(monthObj: any, fieldName: string) {
        if (!monthObj[fieldName]) { monthObj[fieldName] = 0; }
         return monthObj[fieldName];
    }

    private getSelectedObj(guIndx: number, prgIndx: number, subIndx: number, spfIndx: number, formIndx: number | null) {
        let obj: any = {};
        if (formIndx === null) {
            obj = this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx];
        } else {
            obj = this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].child_list[formIndx];
        }
        return obj;
    }

    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    private chgValue(spfObj: any, fieldName: string, chldIndx?: number) {
        let obj = spfObj;
        if (chldIndx !== undefined) { obj = spfObj.child_list[chldIndx]; }
        if (parseFloat(String(obj[fieldName])).isNaN) { obj[fieldName] = 0; }
        obj[fieldName] = parseInt((obj[fieldName] * 10).toString()) / 10;
        const tableData = this.tableData.slice();
        if (chldIndx !== undefined) {
            tableData[spfObj._indxArr].child_list[chldIndx][fieldName] = obj[fieldName];
        } else {
            tableData[spfObj._indxArr][fieldName] = obj[fieldName];
            if (obj[fieldName] === 0 && tableData[spfObj._indxArr].child_list) {
                for (const el of tableData[spfObj._indxArr].child_list) {
                    el[fieldName] = 0;
                }
            }
        }

        // --- проверка чтобы сумма дочерних не была больше суммы родителя
        /*if (tableData[spfObj._indxArr].child_list) {
            let chldSum = 0;
            for (const el of tableData[spfObj._indxArr].child_list) {
                chldSum += el[fieldName];
            }
            if (tableData[spfObj._indxArr][fieldName]<chldSum) { tableData[spfObj._indxArr][fieldName] = chldSum; }
        }*/
        // --
        this.$emit('chgData', { tableData, indx: spfObj._indxArr, chldIndx: chldIndx, fieldName: fieldName });
        // obj[fieldName] = Math.round(parseFloat(String(obj[fieldName])) * 100) / 100;
    }

    // ------
    private async nextInput(monthIndx: number, guIndx: number, prgIndx: number, subIndx: number, spfIndx: number, chldIndx = null) {
        let indxObj: any = { guIndx, prgIndx, subIndx, spfIndx, chldIndx };
        if (chldIndx === null) {
            indxObj = this.getNextInputIndx(guIndx, prgIndx, subIndx, spfIndx, chldIndx);
        } else {
            // @ts-ignore
            if (this.treeData[indxObj.guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].child_list.length - 1 > chldIndx) {
                if (indxObj.chldIndx!==null) { indxObj.chldIndx++; }
            } else {
                indxObj = this.getNextInputIndx(guIndx, prgIndx, subIndx, spfIndx, chldIndx);
            }
        }

        let name = '';
        this.treeData[indxObj.guIndx].vals[indxObj.prgIndx].collapse = false;
        this.treeData[indxObj.guIndx].vals[indxObj.prgIndx].vals[indxObj.subIndx].collapse = false;
        if (indxObj.chldIndx === null) {
            name = `inp_${indxObj.guIndx}_${indxObj.prgIndx}_${indxObj.subIndx}_${indxObj.spfIndx}_month${monthIndx}`;
        } else {
            name = `inp_${indxObj.guIndx}_${indxObj.prgIndx}_${indxObj.subIndx}_${indxObj.spfIndx}_${indxObj.chldIndx}_month${monthIndx}`;
            this.treeData[indxObj.guIndx].vals[indxObj.prgIndx].vals[indxObj.subIndx].vals[indxObj.spfIndx].collapse = false;
        }
        await this.$nextTick();
        // eslint-disable-next-line
        // @ts-ignore
        if (this.$refs[name]) { this.$refs[name][0].select(); }
        if (!(this.selectedRow && this.selectedRow.prgIndx === indxObj.prgIndx && this.selectedRow.subIndx === indxObj.subIndx && this.selectedRow.spfIndx === indxObj.spfIndx && this.selectedRow.formIndx === indxObj.chldIndx)) {
            this.selectRow(indxObj.guIndx, indxObj.prgIndx, indxObj.subIndx, indxObj.spfIndx, indxObj.chldIndx);
        }
    }

    private getNextInputIndx(guIndx: number, prgIndx: number, subIndx: number, spfIndx: number, chldIndx: number | null) {
        let newSpfIndx = spfIndx;
        let newSubIndx = subIndx;
        let newPrgIndx = prgIndx;
        let newGuIndx = guIndx;
        let newChldIndx = null;
        if (this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals.length-1 > spfIndx){
            newSpfIndx++;
        } else {
            if (chldIndx === null && this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].child_list && this.treeData[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].child_list.length) {
                newChldIndx = 0;
            } else {
                if (this.treeData[guIndx].vals[prgIndx].vals.length - 1 > subIndx) {
                    newSubIndx++;
                    newSpfIndx = 0;
                } else {
                    if (this.treeData[guIndx].vals.length - 1 > prgIndx) {
                        newPrgIndx++;
                        newSubIndx = 0;
                        newSpfIndx = 0;
                    } else {
                        if (this.treeData.length - 1 > guIndx) {
                            newGuIndx++;
                            newPrgIndx = 0;
                            newSubIndx = 0;
                            newSpfIndx = 0;
                        } else {
                            newGuIndx = 0;
                            newPrgIndx = 0;
                            newSubIndx = 0;
                            newSpfIndx = 0;
                        }
                    }
                }
            }
        }
        return { guIndx: newGuIndx, prgIndx: newPrgIndx, subIndx: newSubIndx, spfIndx: newSpfIndx, chldIndx: newChldIndx };
    }
    // ---------

    private inputFocus(event: any) {
        event.target.select();
    }
}
