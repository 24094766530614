




















































import {Vue, Component, Prop} from 'vue-property-decorator';
import AlterationApplications from "@/modules/budget/alteration/form/tabs/AlterationApplications.vue";
import AlterationApplicationsIncome from "@/modules/budget/alteration/form/tabs/AlterationApplicationsIncome.vue";
import AlterationReferencesABP from "@/modules/budget/alteration/form/tabs/AlterationReferencesABP.vue";

@Component({
    components: {
        'alteration-references-abp': AlterationReferencesABP,
        'alteration-application-uf': AlterationApplications,
        'alteration-application-income': AlterationApplicationsIncome }
})
export default class ApplForm extends Vue {
    @Prop({
        required: true,
        default: null
    })
    public requestTypeOptions: any;
    @Prop({
        required: true,
        default: null
    })
    public regionBase: any;
    @Prop({
        required: true,
        default: null
    })
    public yearList: any;
    @Prop({
        required: true,
        default: null
    })
    public userId: any;

    @Prop({
        required: true,
        default: null
    })
    public applicationsItem: any;

    private applicationsABP = false;
    private applicationsUO = false;
    private applicationsIncome = false;
    private reLoadChildABP = false;
    public accessLevels = {};
    private moduleCode = '005.002.002';
    public created() {
        this.$watch('applicationsItem', () => {
            if (this.applicationsItem.level === 'abp') {
                this.applicationsABP = this.applicationsItem && this.applicationsItem !== null && this.applicationsItem.requestType !== 'individual';
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.altAllAbp.altAllAbp(this.applicationsItem);

            }
        });
        // this.applicationsABP = this.applicationsItem && this.applicationsItem !== null && this.applicationsItem.requestType !== 'individual';
    }
    public mounted() {
        this.getAccess();
    }
    public async getAccess() {
        try {
            const result = await fetch(`/api-py/get-access-info/${this.moduleCode}/${this.userId}`);
            if (result.status === 200) {
                const res = await result.json();
                if (res.id > 0) {
                    res.editAccess = res['access_level'] !== 1;
                    this.accessLevels = res;
                }
            } else {
                this.makeToast('danger', 'get-access', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-access', error.toString());
        }
    }
    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    } // сообщение
    private applicationsABPActive(currentAlteration: {}){
        // eslint-disable-next-line
        // @ts-ignore
        this.applicationsABP = false;
        this.applicationsUO = true;
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.altApplAbp.altApplAbp(currentAlteration);
        this.$emit('reqABPActive', currentAlteration);
    }

    private referencesABPActive(currentAlteration: {}){
        // eslint-disable-next-line
        // @ts-ignore
        if (this.accessLevels.position === 'abp') {
            // eslint-disable-next-line
            // @ts-ignore
            if (currentAlteration.requestType === 'individual') {
                // eslint-disable-next-line
                // @ts-ignore
                this.$emit('refsABPActive', currentAlteration);
                // this.$refs.altRefAbp.altRefAbp(currentAlteration);
                // eslint-disable-next-line
                // @ts-ignore
                this.applicationsABP = false;
            } else {
                // eslint-disable-next-line
                // @ts-ignore
                this.$emit('applsABPActive', currentAlteration);
            }
        }
        else {

        }
    }
    private onReload(val: boolean) {
        this.$emit('reLoadC', val);
    }
    private onReloadABP(val: boolean) {
        this.reLoadChildABP = val;
    }
    private unReLoadChildABP(val: boolean) {
        this.reLoadChildABP = val;
    }
}
