<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!--------------- Фмнансовый год ------------------->
                            <div class="filter-block">
                                <b-form-group label="Фмнансовый год">
                                    <multiselect v-show="year"
                                                 ref="bcPlanYear"
                                                 v-model="planPeriod"
                                                 track-by="name"
                                                 label="name"
                                                 placeholder="Выбрать год"
                                                 :options="year"
                                                 :searchable="false"
                                                 :allow-empty="false"
                                                 :show-labels="false">
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------data------------------------->
                            <div class="filter-block">
                                <b-form-group label="Дата">
                                    <date-picker
                                        placeholder="Выберите дату"
                                        v-model="date"
                                        format = "YYYY-DD-MM"
                                        type="date"
                                        style="max-width: 1200px"
                                        @input="loadData"
                                        ref="filterDate"
                                    />
                                </b-form-group>
                            </div>
                            <!---------------------Регион---------------------->
                            <div class="filter-block">
                                <b-form-group label="Область/район">
                                    <multiselect
                                        v-model="currRegion"
                                        label="localText"
                                        placeholder="Выберите регион"
                                        :options="regionBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        ref="filterReg"
                                    />
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-button variant="primary" @click="loadData()">Применить</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
        </div>

        <div class="actions-tab in-tab">
            <!---------------Форма для скачивания отчета--------------->
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="download">Балансировка бюджета</b-dropdown-item>
            </b-dropdown>
        </div>
        <!--  хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="date" @click="openFilterByRef('filterDate')"> {{ date | formatDate }} </span>
            <span class="item-breadcrumb" v-if="currRegion" @click="openFilterByRef('filterReg')"> {{ currRegion.localText}} </span>
        </div>
        <div class="top-table-line"></div>
        <balance-tab :table-data="budgetForm" :accessLevel="accessLevels"/>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VueElementLoading from "vue-element-loading";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment';
import balanceTab from "./tableDir/balanceTab";
import {Ax} from "@/utils";
// расходы
export default {
    name: "balanceTable",
    components: { DatePicker, 'loading': VueElementLoading, balanceTab },
    props: [ "accessLevels", "userId", "regionBase" ],
    data() {
        return {
            date: new Date(),
            loading: false,
            currRegion: null,
            budgetForm: [],
            planPeriod: null,
            year: []
        }
    },
    async mounted() {
        this.loading = true;
        this.years(this, this.year);
        this.currRegion = this.regionBase[0];
        if (this.currRegion !== undefined) {
            await this.loadData();
        }
        this.loading = false;
    },
    watch: {
        "regionBase": function () {
            this.currRegion = this.regionBase[0];
            setTimeout(() => this.loadData(), 100);
        }
    },
    methods: {
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'filterDate':
                    refItem = this.$refs.filterDate;
                    break;
                case 'filterReg':
                    refItem = this.$refs.filterReg;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        async loadData() {
            let response = [];
            let params = {};
            try {
                this.budgetForm = [];
                params = `{"year":"${this.planPeriod.year}", "date":"${moment(this.date).format('YYYY-MM-DD')}", "region":"${this.currRegion.code}"}`;
                response = await fetch(`/api-py/alteration-balance/` + params);
                this.budgetForm = await response.json();
            } catch (error) {
                response = [];
                this.loading = false;
                this.makeToast("danger", "Ошибка загрузки баланса", 'Не все параметры заданы');
            }
        },
        years(vue, arrYears) {
            const curYear = new Date().getFullYear();
            for (let i = curYear + 1; i >= 2023; i--) {
                const item = {year: i, name: `${i}`};
                arrYears.push(item);
                if (item.year === curYear) {
                    vue.planPeriod = item;
                }
            }
        },
        download(val) {
            const that = this;
            const params = `{"year":"${this.planPeriod.year}", "date":"${moment(this.date).format('YYYY-MM-DD')}", "region":"${this.currRegion.code}"}`;
            Ax(
                {
                    url: '/api-py/balance-download/' +  encodeURI(params), //  Тело файла
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let name = ''
                    name += `_${that.currRegion.code}_${moment(that.date).format('DD.MM.YYYY')}`
                    link.setAttribute('download', `Балансировка бюджета${name}.xls`);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );

        }
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
}
</script>


<style lang="scss" scoped>
.content {
    display: flex;
    gap: 20px;
    background-color: #eef1f8;
    justify-content: space-between;
}

.content {
    height: 100%;
    .title {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 20px;
        margin-bottom: 26px;
    }
    .constructor,
    .forms {
        flex: 1;
    }

    .body {
        background-color: #f7f9fc;
    }

    .item {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .multiselect {
        max-width: 400px;
    }

    .footer {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: end;
        border: 1px solid #d3dbe3;
        border-top: 0;
        padding-right: 40.5%;
    }

    .vertical-line {
        width: 1px;
        background-color: #d3dbe3;
        margin-top: 40px;
    }
}

.button {
    border: none;
    border-radius: 10px;
}

.button-close {
    color: red;
}

.date-picker-container {
    display: flex;
    gap: 79px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6087a0;

    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
}

</style>