







































import {Component, Prop, Vue} from "vue-property-decorator";
import axios from 'axios'
import {makeToast} from "@/modules/budget/bip/bip-types";

@Component({
    components: {}
})
export default class ErrorModal  extends Vue {
    @Prop({
        required: false,
        default: false
    })
    private show!: any;

    @Prop({
        required: false,
        default: []
    })
    private tableData: any;
    @Prop({
        required: false,
        default: false
    })
    private requestLevel!: any;
    private showModal = false;
    private monthLstRu: any[] = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];
    private typeLstRu: any[] = ['Обязательства', 'Платежи'];
    private created() {
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
            }
            that.$emit('shown');
        })
        if (that.show) {
            that.showModal = that.show;
        }
        that.$emit('shown');
    }
    private padLeadingZeros(num: string, size: number) {
        let s = num;
        while (s.length < size) { s = '0' + s; }
        return s;
    }
    private onApprove() {
        const that = this;
        that.$emit('onApprove', true);
        that.$bvModal.hide('bv-error-modal')
    }
}
