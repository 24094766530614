<template>
    <div>
        <div>
            <!---------------Фильтр--------------->
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <div class="filter-block">
                                <b-form-group  label="Проект">
                                    <div>
                                        <b-form-checkbox v-model="project"  @input="loadData()" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                            <!--------------- Фмнансовый год ------------------->
                            <div class="filter-block">
                                <b-form-group label="Финансовый год">
                                    <multiselect v-show="year"
                                                 ref="bcPlanYear"
                                                 v-model="planPeriod"
                                                 track-by="name"
                                                 label="name"
                                                 placeholder="Выбрать год"
                                                 :options="year"
                                                 :searchable="false"
                                                 :allow-empty="false"
                                                 :show-labels="false">
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------data------------------------->
                            <div class="filter-block">
                                <b-form-group label="Дата">
                                    <date-picker
                                        placeholder="Выберите дату"
                                        v-model="date"
                                        format = "YYYY-DD-MM"
                                        type="date"
                                        style="max-width: 1200px"
                                        ref="filterDate"
                                        :append-to-body="false"
                                    />
                                </b-form-group>
                            </div>
                            <!---------------------АБП------------------------->
                            <div class="filter-block">
                                <b-form-group label="АБП">
                                    <multiselect
                                        v-model="currAbp"
                                        label="localText"
                                        placeholder="Выберите АБП"
                                        :options="abpBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="loadFilters"
                                        ref="filterAbp"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Подведомственные-------------------->
                            <div class="filter-block" v-if="currAbp && [261].includes(currAbp.abp) && (abp !== 0)">
                                <b-form-group label="Подведомственные">
                                    <multiselect
                                        v-model="abp261gu"
                                        :options="departGU"
                                        label="localText"
                                        @input="sortData"
                                        ref="refDepartGU"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------ГУ-------------------------->
<!--                            <div class="filter-block">-->
<!--                                <b-form-group  label="Подведомственные ГУ" v-if="visible">-->
<!--                                    <div class="modal__toggle">-->
<!--                                        <b-form-checkbox v-model="checkedGU" @input="checkGU" switch/>-->
<!--                                    </div>-->
<!--                                </b-form-group>-->
<!--                            </div>-->
                            <div class="filter-block">
                                <b-form-group label="ГУ"> <!--v-if="['ipf', 'uf'].includes(accessLevels.position) || (['abp'].includes(accessLevels.position) && checkedGU)"-->
                                    <multiselect
                                        :disabled="!guBase.length"
                                        v-model="currGu"
                                        label="localText"
                                        placeholder="Выберите ГУ"
                                        :options="guBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        ref="filterGu"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!-----------------Программа ---------------------->
                            <div class="filter-block">
                                <b-form-group label="Программа">
                                    <multiselect
                                        v-model="curPrg"
                                        label="localText"
                                        track-by="prg"
                                        placeholder="Выбрать программу"
                                        :options="prgBase"
                                        :multiple="true"
                                        :searchable="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :preserve-search="true"
                                        @input="chgPrg"
                                        ref="curProgRef"
                                    >
                                        <template slot="beforeList"><b-badge pill variant="primary" @click="selectAllPrgChanged()">Все</b-badge></template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <template v-if="!isOpen"><template v-if="values.length > 1">
                                                <span class="multiselect__single_prg"
                                                      id="multiselect__single_prg">
                                                    -- {{ displayPrgText(values.length) }} --
                                                </span>
<!--                                                    <b-tooltip target="multiselect__single_prg" placement="left" variant="info">-->
<!--                                                        <p style="margin-bottom: 0" v-for="prg in values"-->
<!--                                                           :key="prg.prg">-->
<!--                                                            {{ prg.localText }}</p>-->
<!--                                                    </b-tooltip>-->
                                                </template>
                                                <template v-else-if="values.length === 1">
                                                    <span>
                                                        {{ values[0].localText }}
                                                    </span>
                                                </template>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!-----------------Подпрограмма -------------------->
                            <div class="filter-block">
                                <b-form-group label="Подпрограмма">
                                    <multiselect
                                        v-model="curPpr"
                                        label="localText"
                                        track-by="ppr"
                                        placeholder="Выбрать подпрограмму"
                                        :options="pprBase"
                                        :multiple="true"
                                        :searchable="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :preserve-search="true"
                                        @input="chgPpr"
                                        ref="subProgRef">
                                        <template slot="beforeList"><b-badge pill variant="primary" @click="selectAllPprChanged()">Все</b-badge></template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <template v-if="!isOpen">
                                                <template v-if="values.length > 1">
                                                <span class="multiselect__single"
                                                      id="multiselect__single_ppr">
                                                    -- {{ displayPprText(values.length) }} --
                                                </span>
<!--                                                    <b-tooltip target="multiselect__single_ppr" placement="left" variant="info">-->
<!--                                                        <p style="margin-bottom: 0" v-for="ppr in values"-->
<!--                                                           :key="ppr.ppr">-->
<!--                                                            {{ ppr.localText }}</p>-->
<!--                                                    </b-tooltip>-->
                                                </template>
                                                <template v-else-if="values.length === 1">
                                                    <span>
                                                        {{ values[0].localText }}
                                                    </span>
                                                </template>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!-----------------Специфика------------------------>
                            <div class="filter-block">
                                <b-form-group label="Специфика">
                                    <multiselect
                                        v-model="curSpf"
                                        label="localText"
                                        track-by="spf"
                                        placeholder="Выбрать специфику"
                                        :options="spfBase"
                                        :multiple="true"
                                        :searchable="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :preserve-search="true"
                                        ref="spfRef">
                                        <template slot="beforeList"><b-badge pill variant="primary" @click="selectAllSpfChanged()">Все</b-badge></template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <template v-if="!isOpen">
                                                <template v-if="values.length > 1">
                                                <span class="multiselect__single"
                                                      id="multiselect__single_spf">
                                                    -- {{ displaySpfText(values.length) }} --
                                                </span>
<!--                                                    <b-tooltip target="multiselect__single_spf" placement="left" variant="info">-->
<!--                                                        <p style="margin-bottom: 0" v-for="spf in values"-->
<!--                                                           :key="spf.spf">-->
<!--                                                            {{ spf.spf }} , </p>-->
<!--                                                    </b-tooltip>-->
                                                </template>
                                                <template v-else-if="values.length === 1">
                                                    <span>
                                                        {{ values[0].localText }}
                                                    </span>
                                                </template>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------Регион---------------------->
                            <div class="filter-block">
                                <b-form-group label="Область/район">
                                    <multiselect
                                        v-model="currRegion"
                                        label="text"
                                        placeholder="Выберите регион"
                                        :options="regionBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        ref="filterReg"
                                    />
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group class="special-radio-css" label="Вид данных">
                                    <b-form-radio-group
                                        ref="bcVersion"
                                        id="radio-group-1"
                                        v-model="version"
                                        :options="versions"
                                        name="radio-options_1"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Взаимопогашаемые коды">
                                    <b-form-radio-group
                                        ref="bcVZK"
                                        id="radio-group-2"
                                        v-model="vzk"
                                        :options="vzk_list"
                                        name="radio-options_2"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group  label="Остатки">
                                    <div class="modal__toggle">
                                        <b-form-checkbox v-model="ost" switch/>
                                    </div>
                                </b-form-group>
                                <b-form-group  label="Выгрузка с детализацией">
                                    <div class="modal__toggle">
                                        <b-form-checkbox v-model="fullRep" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-button variant="primary" @click="loadData()">Применить</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <div class="actions-tab in-tab">
            <!---------------Форма для скачивания отчета--------------->
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="download(0)">Скорректированный план(расходы)</b-dropdown-item>
                <b-dropdown-item @click="downloadIPF(0)">Индивидуальный план финансирования (скорректированный)</b-dropdown-item>
                <b-dropdown-item v-if="fullRep" @click="download(1)">Скорректированный план(расходы без ФГ)</b-dropdown-item>
                <b-dropdown-item v-if="fullRep" @click="download(2)">Скорректированный план(расходы без ФГ и АБП)</b-dropdown-item>
<!--                <b-dropdown-item v-if="fullRep" @click="downloads()">Детализированный отчет</b-dropdown-item>-->
            </b-dropdown>
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div class="info-button">
                        <a href="https://youtu.be/yMq8MASuUCI" target="_blank"><span>Видеоинструкция "Формирование скорректированного плана" <i
                            class="icon icon-video"></i></span></a>
                    </div>
<!--                    <div class="info-text">-->
<!--                        <p>Данный режим предназначен для формирования государственным учреждением заявки по внесению изменений-->
<!--                            в индивидуальные планы финансирования по обязательствам и платежам и направления ее-->
<!--                            для утверждения администратору бюджетных программ согласно приказу №540 МФ РК-->
<!--                            «Об утверждении Правил исполнения бюджета и его кассового обслуживания».</p>-->
<!--                    </div>-->
                </b-dropdown-text>
            </b-dropdown>
        </div>
        <!--  хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="project" @click="openFilterByRef('bcProject')"> {{ 'Проект' }} </span>
            <span class="item-breadcrumb" v-if="date" @click="openFilterByRef('filterDate')"> {{ date | formatDate }} </span>
            <span class="item-breadcrumb" v-if="currAbp" @click="openFilterByRef('filterAbp')"> {{currAbp.localText}}-АБП </span>
            <span class="item-breadcrumb" v-if="abp261gu && abp261gu.code !== 0" @click="openFilterByRef('refDepartGU')">
                Подведомственные - {{ abp261gu.code }}
            </span>
            <span class="item-breadcrumb" v-if="(currGu && ['ipf', 'uf'].includes(accessLevels.position))" @click="openFilterByRef('filterGu')"> {{currGu.localText}}-ГУ </span>
            <span class="item-breadcrumb" v-if="currRegion" @click="openFilterByRef('filterReg')"> {{ currRegion.text}} </span>
            <span class="item-breadcrumb" @click="openFilterByRef('bcVersion')"> {{ versions[version].text }} </span>
            <span class="item-breadcrumb" @click="openFilterByRef('bcVZK')"> {{vzk_list[vzk].text }} </span>
        </div>
        <div class="top-table-line"></div>
        <prg-tree-table :table-data="budgetForm" :accessLevel="accessLevels"/>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VueElementLoading from "vue-element-loading";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Ax} from "@/utils"
import moment from 'moment';
import PrgTreeTable from "./PrgTreeTable";
import store from "@/services/store";
// расходы
export default {
    name: "expensTable",
    components: { DatePicker, 'loading': VueElementLoading, PrgTreeTable },
    props: [ "accessLevels", "userId" ],
    data() {
        return {
            date: new Date(),
            loading: false,
            budgetForm: [],
            versions: [
                { value: 0, text: 'Обязательства' },
                { value: 1, text: 'Платежи' }
            ],
            version: 0,
            currAbp: null,
            abpBase: [],
            prgBase: [],
            pprBase: [],
            spfBase: [],
            currGu: null,
            guBase: [],
            regionBase: [],
            currRegion: null,
            ost: false,
            fullRep: true,
            checkedGU: false,
            visible: false,
            vzk_list: [
                {value: 0, text: 'без ВЗК'},
                {value: 1, text: 'только ВЗК'},
                {value: 2, text: 'с ВЗК'}
            ],
            vzk: 2,
            planPeriod: null,
            year: [],
            curPrg: [],
            curPpr: [],
            curSpf: [],
            prg: [],
            ppr: [],
            spf: [],
            abp261gu: null,
            departGU: [],
            arrObligation: [],
            arrPayment: [],
            project: false,
            arrGU: {},
            abp: 0,
            baseFilters: [],
            spfName: ''
        }
    },
    mounted() {
        this.version = 0;
        // this.visible = ['abp'].includes(this.accessLevels.position);
        this.checkedGU = ['ipf', 'uf'].includes(this.accessLevels.position);
        this.years(this, this.year);
    },
    created() {
        this.loadAbpList();
        this.$watch('accessLevels', function () {
            // this.visible = ['abp'].includes(this.accessLevels.position);
            this.checkedGU = ['ipf', 'uf'].includes(this.accessLevels.position);
        });
        this.$watch('currRegion', function () {
            this.loadFiltersData();
            this.loadDepartGU();
            this.loadData();
        });

    },
    computed: {
        isAllPrgSelected() {
            return (this.curPrg.length === this.prgBase.length)
        },
        isAllPprSelected() {
            return (this.curPpr.length === this.pprBase.length)
        },
        isAllSpfSelected() {
            return (this.curSpf.length === this.spfBase.length)
        }
    },
    methods: {
        chgParams() {
            switch (this.accessLevels.position) {
                case 'ipf':
                    if (!this.currRegion || !this.currAbp || !this.currGu) { return; }
                    break;
                case 'abp':
                    if (!this.currRegion || !this.currAbp) { return; }
                    break;
                case 'uf':
                    if (!this.currRegion) { return; }
                    break;
                default: break;
            }
            this.loadData();
        },
        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-uuid/' + store.state.user.sub);
                if (response.status === 200) {
                    this.regionBase = await response.json();
                    this.currRegion = this.regionBase[0];
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов
        checkGU() {
            if (this.checkedGU) {
                this.currGu = this.guBase[0];
            } else {
                this.currGu = null;
            }
        },
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'bcVersion':
                    refItem = this.$refs.bcVersion;
                    break;
                case 'filterDate':
                    refItem = this.$refs.filterDate;
                    break;
                case 'filterAbp':
                    refItem = this.$refs.filterAbp;
                    break;
                case 'filterGu':
                    refItem = this.$refs.filterGu;
                    break;
                case 'filterReg':
                    refItem = this.$refs.filterReg;
                    break;
                case 'bcVZK':
                    refItem = this.$refs.bcVZK;
                    break;
                case 'refDepartGU':
                    refItem = this.$refs.refDepartGU;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        download(val) {
            const that = this;
            let cAbp = null;
            let cGu = null;
            if ((this.currGu === null) || (this.currGu === undefined)) {
                cGu = null;
            } else {
                if (that.currGu.code === 0) {
                    cGu = null;
                    if (this.arrGU.length > 0) {
                        cGu = this.arrGU;
                    }
                } else {
                    cGu = "'" + that.currGu.code.toString() + "'";
                }
            }
            if ((this.currAbp === null)  || (this.currAbp === undefined)) {
                cAbp = null;
            } else {
                if (this.currAbp.abp === 0) {
                    cAbp = null;
                } else {
                    cAbp = this.currAbp.abp;
                }
            }
            const prgCount = [];
            const pprCount = [];
            const spfCount = [];
            if (this.curPrg.length !== this.prgBase.length) {
                this.curPrg.forEach((row, ind) => { prgCount.push(row.prg); });
            }
            if (this.curPpr.length !== this.pprBase.length) {
                this.curPpr.forEach((row, ind) => { pprCount.push(row.ppr); });
            }
            if (this.curSpf.length !== this.spfBase.length) {
                this.curSpf.forEach((row, ind) => { spfCount.push(row.spf); });
            }
            const params = JSON.stringify({
                abp: cAbp,
                gu: cGu,
                type: that.version,
                level: that.accessLevels.position,
                region: that.currRegion.code,
                regionText: that.currRegion.text,
                year: this.planPeriod.year,
                date: moment(that.date).format('YYYY-MM-DD'),
                dateRt: moment(that.date).format('DD.MM.YYYY'),
                ost: that.ost,
                vzk: that.vzk,
                project: that.project,
                fullRep: this.fullRep,
                locale: this.$i18n.locale,
                details: val,
                uid: this.userId,
                prgCount: prgCount,
                pprCount: pprCount,
                spfCount: spfCount
            });
            Ax(
                {
                    url: '/api-py/scorr-plan-expens-download/' +  encodeURI(params), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let name = ''
                    if (cAbp) {
                      name += `_${cAbp}`
                    }
                    if (cGu) {
                      name += `_${cGu.replace(/'/g, "")}`
                    }
                    name += `_${that.currRegion.code}_${moment(that.date).format('DD.MM.YYYY')}`

                    switch (this.$i18n.locale) {
                        case 'kk':
                            if (val === 0) {
                                link.setAttribute('download', `Түзетілген жоспар(Шығыс)${name}.xls`);// or any other extension
                            } else {
                                if (val === 1) {
                                    link.setAttribute('download', `Түзетілген жоспар(Шығыс ФГсыз)${name}.xls`);// or any other extension
                                } else {
                                    if (val === 2) {
                                        link.setAttribute('download', `Түзетілген жоспар(Шығыс ФГ жөне АБПсыз)${name}.xls`);// or any other extension
                                    }
                                }
                            }
                            break;
                        default:
                            if (val === 0) {
                                link.setAttribute('download', `Скорректированный план(Расходы)${name}.xls`);// or any other extension
                            } else {
                                if (val === 1) {
                                    link.setAttribute('download', `Скорректированный план(Расходы без ФГ)${name}.xls`);// or any other extension
                                } else {
                                    if (val === 2) {
                                        link.setAttribute('download', `Скорректированный план(Расходы без ФГ и АБП)${name}.xls`);// or any other extension
                                    }
                                }
                            }
                    }
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );

        },
        downloadIPF(val) {
            const that = this;
            let cAbp = null;
            let cGu = null;
            if ((this.currGu === null) || (this.currGu === undefined)) {
                cGu = null;
            } else {
                if (that.currGu.code === 0) {
                    cGu = null;
                    if (this.arrGU.length > 0) {
                        cGu = this.arrGU;
                    }
                } else {
                    cGu = "'" + that.currGu.code.toString() + "'";
                }
            }
            if ((this.currAbp === null)  || (this.currAbp === undefined)) {
                cAbp = null;
            } else {
                if (this.currAbp.abp === 0) {
                    cAbp = null;
                } else {
                    cAbp = this.currAbp.abp;
                }
            }
            const prgCount = [];
            const pprCount = [];
            const spfCount = [];
            if (this.curPrg.length !== this.prgBase.length) {
                this.curPrg.forEach((row, ind) => { prgCount.push(row.prg); });
            }
            if (this.curPpr.length !== this.pprBase.length) {
                this.curPpr.forEach((row, ind) => { pprCount.push(row.ppr); });
            }
            if (this.curSpf.length !== this.spfBase.length) {
                this.curSpf.forEach((row, ind) => { spfCount.push(row.spf); });
            }
            let guText = that.currGu.name_ru;
            if (this.$i18n.locale === 'kk') {guText = that.currGu.name_kk;}

            const params = JSON.stringify({
                abp: cAbp,
                gu: cGu,
                guText: guText,
                type: that.version,
                level: that.accessLevels.position,
                region: that.currRegion.code,
                regionText: that.currRegion.text,
                year: this.planPeriod.year,
                date: moment(that.date).format('YYYY-MM-DD'),
                dateRt: moment(that.date).format('DD.MM.YYYY'),
                ost: that.ost,
                vzk: that.vzk,
                project: that.project,
                fullRep: this.fullRep,
                locale: this.$i18n.locale,
                details: val,
                uid: this.userId,
                prgCount: prgCount,
                pprCount: pprCount,
                spfCount: spfCount
            });
            let name = ''
            let filename = ''
            if (cAbp) {
                name += `_${cAbp}`
            }
            if (cGu) {
                name += `_${cGu.replace(/'/g, "")}`
            }
            name += `_${that.currRegion.code}_${moment(that.date).format('DD.MM.YYYY')}`

            switch (this.$i18n.locale) {
                case 'kk':
                    if (val === 0) {
                        filename =`ИПФ (скорректированный)_${name}.xls`;// or any other extension
                    }
                    break;
                default:
                    if (val === 0) {
                        filename =`ИПФ (скорректированный)_${name}.xls`;// or any other extension
                    }
            }
            Ax(
                {
                    url: '/api-py/scorr-plan-expens-download-IPF/' + encodeURI(params), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let name = ''
                    if (cAbp) {
                        name += `_${cAbp}`
                    }
                    if (cGu) {
                        name += `_${cGu.replace(/'/g, "")}`
                    }
                    name += `_${that.currRegion.code}_${moment(that.date).format('DD.MM.YYYY')}`

                    switch (this.$i18n.locale) {
                        case 'kk':
                            if (val === 0) {
                                link.setAttribute('download', `ИПФ (скорректированный)_${name}.xls`);// or any other extension
                            }
                            break;
                        default:
                            if (val === 0) {
                                link.setAttribute('download', `ИПФ (скорректированный)_${name}.xls`);// or any other extension
                            }
                    }
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );

        },
        downloads() {
            const that = this;
            let cAbp = null;
            let cGu = null;
            if ((this.currGu === null) || (this.currGu === undefined)) {
                cGu = null;
            } else {
                if (that.currGu.code === 0) {
                    cGu = null;
                    if (this.arrGU.length > 0) {
                        cGu = this.arrGU;
                    }
                } else {
                    cGu = "'" + that.currGu.code.toString() + "'";
                }
            }
            if ((this.currAbp === null)  || (this.currAbp === undefined)) {
                cAbp = null;
            } else {
                if (this.currAbp.abp === 0) {
                    cAbp = null;
                } else {
                    cAbp = this.currAbp.abp;
                }
            }
            const prgCount = [];
            const pprCount = [];
            const spfCount = [];
            if (this.curPrg.length !== this.prgBase.length) {
                this.curPrg.forEach((row, ind) => { prgCount.push(row.prg); });
            }
            if (this.curPpr.length !== this.pprBase.length) {
                this.curPpr.forEach((row, ind) => { pprCount.push(row.ppr); });
            }
            if (this.curSpf.length !== this.spfBase.length) {
                this.curSpf.forEach((row, ind) => { spfCount.push(row.spf); });
            }
            const params = JSON.stringify({
                abp: cAbp,
                gu: cGu,
                type: that.version,
                level: that.accessLevels.position,
                region: that.currRegion.code,
                year: this.planPeriod.year,
                date: moment(that.date).format('YYYY-MM-DD'),
                ost: that.ost,
                vzk: that.vzk,
                project: that.project,
                fullRep: this.fullRep,
                locale: this.$i18n.locale,
                uid: this.userId,
                prgCount: prgCount,
                pprCount: pprCount,
                spfCount: spfCount
            });
            Ax(
                {
                    url: '/api-py/scorr-plan-expens-downloads/' +  encodeURI(params), //  Тело файла
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let name = ''
                    if (cAbp) {
                        name += `_${cAbp}`
                    }
                    if (cGu) {
                        name += `_${cGu.replace(/'/g, "")}`
                    }
                    name += `_${that.currRegion.code}_${moment(that.date).format('DD.MM.YYYY')}`

                    switch (this.$i18n.locale) {
                        case 'kk':
                            link.setAttribute('download', `Түзетілген жоспар(Шығыс)${name}.xls`);// or any other extension
                            break;
                        default:
                            link.setAttribute('download', `Скорректированный план(Расходы)${name}.xls`);// or any other extension
                    }
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );

        },
        async loadAbpList() {
            let response = [];
            response = await fetch('/api-py/user-abp-list/' + this.userId);
            const newBase = await response.json();
            this.abpBase = this.setIdArr(newBase, "abp");
            if (['uf'].includes(this.accessLevels.position)) {
                this.abpBase.push({
                    "name_ru": "Все",
                    "name_kk": "Барлығы",
                    "abp": 0,
                    "check": true
                });
            }
            if (this.abpBase.length > 0) {
                this.abpBase
                    .sort((a, b) => (a.abp - b.abp > 0 ? 1 : -1))
                    .map((item) => {
                        if (item.abp == 0) {item.localText = `${item["name_" + this.$i18n.locale]}`}
                        else
                            {item.localText = `${item.abp} - ${item["name_" + this.$i18n.locale]}`;
                            item.check = false}
                        return item;
                    });
                this.currAbp = this.abpBase[0];
            }
            await this.loadCompany();
            await this.loadRegions();
        },
        setIdArr(arr, codeName) {
            const result = [];
            for (const el of arr) {
                if (
                    result.length > 0 &&
                    el[codeName] === result[result.length - 1][codeName]
                ) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({ idArr: [el.id] }, el));
                }
            }
            return result;
        },
        async loadCompany() {
            let result = [];
            this.curComp = null;
            if (this.currAbp !== null) {
                this.guBase = [];
                if (this.abp !== 0 && [261].includes(this.currAbp.abp)) {
                    // 261 abp
                    await this.loadDepartGU();
                } else {
                    try {
                        result = await fetch(`/api/budget-execution-base/user/gu?abp=${this.currAbp.abp}`)
                            .then(response => response.json());
                    } catch (error) {
                        result = [];
                        this.makeToast('danger', 'Ошибка загрузки гос. учреждений', error.toString());
                    }
                    this.abp261gu = null;
                }
                if (result.length > 0) {
                    this.curComp = this.setNameLang(result[0], 'code');
                }
                this.guBase = result;
                if (['abp', 'uf'].includes(this.accessLevels.position)) {
                    this.guBase.push({
                        "name_ru": "Все",
                        "name_kk": "Барлығы",
                        "code": 0
                    });
                }
                this.guBase.sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
                    .map((item) => {
                        if (item.code == 0) {item.localText = `${item["name_" + this.$i18n.locale]}`}
                        else
                        {item.localText = `${item.code} - ${item["name_" + this.$i18n.locale]}`}
                        return item;
                    });
                this.currGu = this.guBase[0];
            }
        },
        setNameLang(obj, codeName) {
            if (obj === null) { return null; }
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        async loadData() {
            const that = this;
            this.loading = true;
            let response = [];
            try {
                this.budgetForm = [];
                if (that.currRegion !== null) {
                    let cAbp = null;
                    let cGu = null;
                    if ((that.currGu === null) || (that.currGu === undefined)) {
                        cGu = null;
                    } else {
                        if (that.currGu.code === 0) {
                            cGu = null;
                            if (this.arrGU.length > 0) {
                                cGu = this.arrGU;
                            }
                        } else {
                            cGu = "'" + that.currGu.code.toString() + "'";
                        }
                    }
                    if ((that.currAbp === null) || (that.currAbp === undefined)) {
                        cAbp = null;
                    } else {
                        if (that.currAbp.abp === 0) {
                            cAbp = null;
                        } else {
                            cAbp = that.currAbp.abp;
                        }
                    }
                    const prgCount = [];
                    const pprCount = [];
                    const spfCount = [];
                    if (this.curPrg.length !== this.prgBase.length) {
                        this.curPrg.forEach((row, ind) => { prgCount.push(row.prg); });
                    }
                    if (this.curPpr.length !== this.pprBase.length) {
                        this.curPpr.forEach((row, ind) => { pprCount.push(row.ppr); });
                    }
                    if (this.curSpf.length !== this.spfBase.length) {
                        this.curSpf.forEach((row, ind) => { spfCount.push(row.spf); });
                    }
                    const params = JSON.stringify({
                        abp: cAbp,
                        gu: cGu,
                        type: that.version,
                        level: that.accessLevels.position,
                        region: that.currRegion.code,
                        year: this.planPeriod.year,
                        date: moment(that.date).format('YYYY-MM-DD'),
                        ost: that.ost,
                        vzk: that.vzk,
                        project: that.project,
                        fullRep: this.fullRep,
                        locale: this.$i18n.locale,
                        uid: this.userId,
                        prgCount: prgCount,
                        pprCount: pprCount,
                        spfCount: spfCount
                    });
                    response = await fetch(`/api-py/scorr-plan-expens/` + encodeURI(params));
                    this.budgetForm = await response.json();
                }
                this.loading = false;
            } catch (error) {
                response = [];
                this.loading = false;
                this.makeToast(
                    "danger",
                    "Ошибка загрузки  СкоррПлан(Расходы)",
                    'Не все параметры заданы'
                );
            }
            this.loading = false;
        },
        years(vue, arrYears) {
            const curYear = new Date().getFullYear();
            for (let i = curYear + 1; i >= 2023; i--) {
                const item = {year: i, name: `${i}`};
                arrYears.push(item);
                if (item.year === curYear) {
                    vue.planPeriod = item;
                }
            }
        },
        async loadDepartGU() {
            const that = this;
            let params = {};
            try {
                let cAbp = null;
                let cGu = null;
                if ((that.currGu === null) || (that.currGu === undefined)) {
                    cGu = null;
                } else {
                    if (that.currGu.code === 0) {
                        cGu = null;
                    } else {
                        cGu = that.currGu.code;
                    }
                }
                if ((that.currAbp === null) || (that.currAbp === undefined)) {
                    cAbp = null;
                } else {
                    if (that.currAbp.abp === 0) {
                        cAbp = null;
                    } else {
                        cAbp = that.currAbp.abp;
                    }
                }
                if (that.currRegion !== null) {
                    params = `{"abp":${cAbp},"gu":${cGu},"type":${that.version},"level":"${that.accessLevels.position}", "region":"${that.currRegion.code}", "year":"${this.planPeriod.year}", "date":"${moment(that.date).format('YYYY-MM-DD')}","ost":"${that.ost}","vzk":"${that.vzk}", "project":"${that.project}", "userId":"${this.userId}"}`;
                    const response = await fetch('/api-py/get-abp-261-gu-scorr/' + params);
                    this.departGU = await response.json();
                    if (this.departGU.length > 0) {
                        this.departGU.push({
                            "name_ru": "Все",
                            "name_kk": "Барлығы",
                            "code": 0,
                            "childGu": [],
                            "arrGu": [{
                                "name_ru": "Все",
                                "name_kk": "Барлығы",
                                "code": 0
                            }]
                        });
                        this.departGU
                            .sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
                            .map((item) => {
                                if (item.code == 0) {item.localText = `${item["name_" + this.$i18n.locale]}`}
                                else
                                {item.localText = `${item.code} - ${item["name_" + this.$i18n.locale]}`}
                                return item;
                            });
                        this.abp261gu = this.departGU[0];
                    }
                    this.accessLevels.operations.forEach((op, ind) => {
                        if (op.toLowerCase().lastIndexOf('abp') !== -1) {
                            this.abp += 1;
                        }
                    });
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных ГУ для : ' + store.state.user.sub, error.toString());
            }
        },
        sortData(){
            let listGu = {}
            if (this.abp261gu) {
                if (this.abp261gu && this.abp261gu.code !== 0) {
                    listGu = this.abp261gu.childGu.join(',');
                }
                this.arrGU = listGu;
                if (this.abp261gu && this.abp261gu.arrGu.length > 0) {
                    this.curComp = this.setNameLang(this.abp261gu.arrGu[0], 'code');
                }
                this.guBase = this.abp261gu.arrGu;
                this.guBase.push({
                    "name_ru": "Все",
                    "name_kk": "Барлығы",
                    "code": 0
                });
                if (this.guBase.length !== 0) {
                    this.guBase.sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
                        .map((item, index) => {
                            if (item.code == 0) {
                                item.localText = `${item["name_" + this.$i18n.locale]}`
                            } else {
                                item.localText = `${item.code} - ${item["name_" + this.$i18n.locale]}`
                            }
                            return item;
                        });
                    this.currGu = this.guBase[0];
                }
            }
        },
        loadFilters(){
            this.loadCompany();
            this.loadPrg();
        },
        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }, // сортирует по заданному полю
        async loadFiltersData() {
            const that = this;
            try {
                let cAbp = null;
                let cGu = null;
                if ((that.currGu === null) || (that.currGu === undefined)) {
                    cGu = null;
                } else {
                    if (that.currGu.code === 0) {
                        cGu = null;
                        if (this.arrGU.length > 0) {
                            cGu = this.arrGU;
                        }
                    } else {
                        cGu = "'" + that.currGu.code.toString() + "'";
                    }
                }
                if ((that.currAbp === null) || (that.currAbp === undefined)) {
                    cAbp = null;
                } else {
                    if (that.currAbp.abp === 0) {
                        cAbp = null;
                    } else {
                        cAbp = that.currAbp.abp;
                    }
                }
                if (that.currRegion !== null) {
                    const params = JSON.stringify({
                        abp: cAbp,
                        gu: cGu,
                        type: that.version,
                        level: that.accessLevels.position,
                        region: that.currRegion.code,
                        year: this.planPeriod.year,
                        date: moment(that.date).format('YYYY-MM-DD'),
                        ost: that.ost,
                        vzk: that.vzk,
                        project: that.project,
                        fullRep: this.fullRep,
                        locale: this.$i18n.locale,
                        uid: this.userId
                    });
                    const response = await fetch('/api-py/have-abp-prg-prg-by-scorr-plan/' + encodeURI(params));
                    this.baseFilters = await response.json();
                    this.loadPrg();
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных для фильтра ', error.toString());
            }
        },
        newElem(field, code, text, value) {
            const el = {};
            this.$set(el, field, code);
            this.$set(el, 'localText', text);
            this.$set(el, 'check', false);
            return el;
        }, // формирует элемент для отображения в фильтрах
        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код
        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера
        loadPrg() {
            const that = this;
            const prgF = new Map();
            this.prgBase = [];
            let prgCurr = [];
            if (this.currAbp !== null && this.currAbp.abp > 0) {
                prgCurr = this.baseFilters.filter((item) => item.abp === this.currAbp.abp)
            } else {
                prgCurr = this.baseFilters;
            }
            for (const row of prgCurr) {
                prgF.set(that.getRowKey(row, ['prg']),
                    that.newElem('prg', row['prg'], this.padLeadingZeros(row['prg'], 3) + ' - Программма', row));
            }
            prgF.forEach((row, ind) => {
                this.prgBase.push(that.newElem('prg', row['prg'], row['localText'], row));
            });
            this.prgBase.sort((a, b) => (a.prg - b.prg > 0 ? 1 : -1))
            this.curPrg = this.prgBase.slice();
            this.loadPpr();
        },
        loadPpr() {
            const that = this;
            const pprF = new Map();
            this.pprBase = [];
            let pprCurr = [];
            if (['uf'].includes(this.accessLevels.position)) {
            }
            const prgCount = [];
            if (this.curPrg !== undefined && this.curPrg !== null && Object.keys(this.curPrg).length > 0) {
                this.curPrg.forEach((row, ind) => { prgCount.push(row.prg); });
                if (this.currAbp.abp !== 0) {
                    pprCurr = this.baseFilters.filter((item) => prgCount.includes(item.prg) && (item.abp === this.currAbp.abp));
                } else {
                    pprCurr = this.baseFilters.filter((item) => prgCount.includes(item.prg));
                }
            } else {
                pprCurr = this.baseFilters;
            }
            for (const row of pprCurr) {
                pprF.set(that.getRowKey(row, ['ppr']),
                    that.newElem('ppr', row['ppr'], this.padLeadingZeros(row['ppr'], 3) + ' - Подпрограммма', row));
            }
            pprF.forEach((row, ind) => {
                this.pprBase.push(that.newElem('ppr', row['ppr'], row['localText'], row));
            });
            this.pprBase.sort((a, b) => (a.ppr - b.ppr > 0 ? 1 : -1))
            this.curPpr = this.pprBase.slice();
            this.loadSpf();
        },
        loadSpf() {
            const that = this;
            const spfF = new Map();
            this.spfBase = [];
            let spfCurr = [];
            const prgCount = [];
            const pprCount = [];
            this.curPrg.forEach((row, ind) => { prgCount.push(row.prg); });
            this.curPpr.forEach((row, ind) => { pprCount.push(row.ppr); });
            if (this.currAbp.abp !== 0) {
                spfCurr = this.baseFilters.filter((item) => prgCount.includes(item.prg) && pprCount.includes(item.ppr) && (item.abp === this.currAbp.abp))
            } else {
                spfCurr = this.baseFilters.filter((item) => prgCount.includes(item.prg) && pprCount.includes(item.ppr))
            }
            for (const row of spfCurr) {
                spfF.set(that.getRowKey(row, ['spf']),
                    that.newElem('spf', row['spf'], this.padLeadingZeros(row['spf'], 3) + ' - ' + row['spf_name'], row));
            }
            spfF.forEach((row, ind) => {
                this.spfBase.push(that.newElem('spf', row['spf'], row['localText'], row));
            });
            this.spfBase.sort((a, b) => (a.spf - b.spf > 0 ? 1 : -1))
            this.curSpf = this.spfBase.slice();
        },
        chgPrg() {
            this.loadPpr();
        },
        chgPpr() {
            this.loadSpf();
        },
        selectAllPrgChanged() {
            if (this.isAllPrgSelected) {
                this.curPrg = []
            } else { this.curPrg = this.prgBase.slice() }
        },
        selectAllPprChanged() {
            if (this.isAllPprSelected) {
                this.curPpr = []
            } else { this.curPpr = this.pprBase.slice() }
        },
        selectAllSpfChanged() {
            if (this.isAllSpfSelected) {
                this.curSpf = []
            } else { this.curSpf = this.spfBase.slice() }
        },
        displayPrgText(count){
            if (count === this.prgBase.length){
                return 'Все'
            }
            else if (count ===1){
                return this.value[0].name
            }
            else { return  count + ' программ выбрано'}
        },
        displayPprText(count){
            if (count === this.pprBase.length){
                return 'Все'
            }
            else if (count ===1){
                return this.value[0].name
            }
            else { return  count + ' подпрограмм выбрано'}
        },
        displaySpfText(count){
            if (count === this.spfBase.length){
                return 'Все'
            }
            else if (count ===1){
                return this.value[0].name
            }
            else { return  count + ' специфик выбрано'}
        },
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
}
</script>


<style lang="scss" scoped>
.content {
    display: flex;
    gap: 20px;
    background-color: #eef1f8;
    justify-content: space-between;
}

.content {
    height: 100%;
    .title {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 20px;
        margin-bottom: 26px;
    }
    .constructor,
    .forms {
        flex: 1;
    }

    .body {
        background-color: #f7f9fc;
    }

    .item {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .multiselect {
        max-width: 400px;
    }

    .footer {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: end;
        border: 1px solid #d3dbe3;
        border-top: 0;
        padding-right: 40.5%;
    }

    .vertical-line {
        width: 1px;
        background-color: #d3dbe3;
        margin-top: 40px;
    }
}
.add_modal {
    width:100%;
    height:100%;
    &__body {
        background-color: #f7f9fc;
        border: 1px solid #d3dbe3;
        border-radius: 5px;
        margin-bottom: 23px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .input-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
        &-label {
            width: 400px;
            font-weight: 600;
            font-size: 14px;
            color: #6087a0;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
        }
    }
    .form-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
    }
}
.button {
    border: none;
    border-radius: 10px;
}

.button-close {
    color: red;
}
.second-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 26px;
    &__title {
        font-weight: 500;
        font-size: 14px;
    }
}

.date-picker-container {
    display: flex;
    gap: 79px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6087a0;

    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
}

</style>